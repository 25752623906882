/******************************************************************************************/
/* Footer
/* This file is use to store all the scss related to the footer.
/******************************************************************************************/

.footer {
  width: 100%;
  background-color: rgba($color: $mako, $alpha: 1);
  margin-top: 0px;

  &--container {
    padding: 30px 0px 30px 0px;

    @media #{$medium-device} {
      padding: 60px 0px;
    }
  }

  &__logo-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px 25px;

    &__line {
      width: 100%;
      height: 1px;
      background-color: $tussock;
    }

    &__company {
      width: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 0px 10px;

      &:hover,
      &:active,
      &:focus {
        .footer__logo-bar__company__logo {
          img {
            opacity: 0.65;
          }
        }

        .footer__logo-bar__company__title {
          span {
            opacity: 0.65;
          }
        }
      }

      &__logo {
        height: 45px;
        width: 40px;
        margin: 0px 10px 0px 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 1;
          transition: opacity 0.25s linear;
        }

        span {
          display: none;
        }
      }

      &__title {
        display: block;

        span {
          height: 45px;
          transition: all 0.25s linear;
          font-size: 1em;
          text-transform: uppercase;
          font-family: $libre-baskerville;

          background: -webkit-linear-gradient(left, $copper, $rob-roy);
          background: -o-linear-gradient(right, $copper, $rob-roy);
          background: -moz-linear-gradient(right, $copper, $rob-roy);
          background: linear-gradient(to right, $copper, $rob-roy);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }

  &__layout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media #{$medium-device} {
      justify-content: center;
    }

    &__item {
      display: flex;

      &--container {
      }

      span.row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      span.line {
        width: 100%;
        height: 1px;
        background-color: $tussock;
      }

      span.highlight {
        font-family: $bitter;
        color: rgba($color: $carousel-pink, $alpha: 0.5);
        font-weight: 400;
        letter-spacing: 0px;
        font-size: 1rem;
      }

      span.text {
        color: $white;
        font-size: 1rem;
        font-family: $montserrat;
        text-transform: none;
        letter-spacing: 0px;
      }

      a {
        height: 100%;
        transition: text-decoration-color 0.25s ease-out;

        span {
          transition: color 0.25s ease-out;
        }

        svg {
          width: 22px;
          height: 22px;
          transition: all 0.25s linear;
          color: $white;

          &:hover,
          &:focus,
          &:active {
            color: $tussock;
          }
        }

        &:hover,
        &:focus,
        &:active {
          transition: text-decoration-color 0.25s ease-in, color 0.25s ease-in;
          text-decoration: underline;
          text-decoration-color: $tussock;

          span {
            transition: color 0.25s ease-in;
            color: $tussock;
          }
        }
      }

      ul {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        li {
          padding: 0px 10px;
          height: 35px;
        }
      }

      &--quick-links {
        width: 100%;

        @media #{$medium-device} {
          order: 1;
        }

        .footer__layout__item--container {
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin: 30px 0px 0px 0px;
        }

        span.highlight {
          width: 100%;
        }

        .stickney-chase {
          span {
            color: $tussock;
          }
        }

        .stickney-meadows {
          span {
            color: $tussock;
          }
        }
      }

      &--phone {
        width: 100%;

        @media #{$medium-device} {
          width: 33%;
          order: 3;
        }

        @media #{$large-device} {
          width: 25%;
        }

        .footer__layout__item--container {
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin: 5px 0px 8px 0px;

          @media #{$medium-device} {
            margin: 25px 0px 8px 0px;
            display: flex;
            //border-right: 1px solid rgba($color: $tussock, $alpha: 1);
          }
        }

        span {
          @media #{$medium-device} {
            display: block;
          }
        }

        a {
          @media #{$medium-device} {
            height: auto;
          }
        }

        .highlight {
          margin: 0px 5px;

          @media #{$medium-device} {
            margin: 0px 5px 10px 5px;
          }
        }
      }

      &--email {
        width: 100%;

        @media #{$medium-device} {
          width: 33%;
          order: 5;
        }

        @media #{$large-device} {
          width: 25%;
        }

        .footer__layout__item--container {
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin: 8px 0px 8px 0;

          @media #{$medium-device} {
            display: flex;
            margin: 25px 0px 8px 0px;
            //border-left: 1px solid rgba($color: $tussock, $alpha: 1);
          }
        }

        span {
          @media #{$medium-device} {
            display: block;
          }
        }

        a {
          @media #{$medium-device} {
            height: auto;
          }
        }

        .highlight {
          margin: 0px 5px;

          @media #{$medium-device} {
            margin: 0px 5px 10px 5px;
          }
        }
      }

      &--address {
        width: 100%;

        @media #{$medium-device} {
          width: 33%;
          order: 4;
        }

        @media #{$large-device} {
          width: 50%;
        }

        .footer__layout__item--container {
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin: 8px 0px 8px 0;

          @media #{$medium-device} {
            display: flex;
            margin: 25px 15px 15px 15px;
          }
        }

        span {
          @media #{$medium-device} {
            display: block;
          }
        }

        a {
          @media #{$medium-device} {
            height: auto;
          }
        }

        .highlight {
          margin: 0px 5px 10px 5px;
        }
      }

      &--socials {
        width: 100%;

        @media #{$medium-device} {
          order: 6;
        }

        .footer__layout__item--container {
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin: 8px 0px 25px 0;

          @media #{$medium-device} {
            margin-top: 0px;
          }
        }

        span {
          display: block;
          width: auto;
        }

        .highlight {
          margin: 0px 5px;
        }

        .highlight {
          margin-bottom: 15px;
        }
      }
    }

    div.line {
      width: 100%;
      height: 1px;
      background-color: rgba($color: $tussock, $alpha: 1);

      &.line-1 {
        @media #{$medium-device} {
          order: 2;
        }
      }

      &.line-2 {
        @media #{$medium-device} {
          order: 7;
        }
      }
    }
  }

  &__information-bar {
    background-color: $tawny-port;
    width: 100%;
    height: 40px;

    .container {
      height: 100%;
    }

    &__section {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      text-align: center;

      span {
        color: $white;
        font-size: 0.75rem;
        font-family: $libre-baskerville;
      }
    }

    &--stickney-meadows {
      background-color: $stickney-meadows;
    }

    &--stickney-chase {
      background-color: $stickney-chase;
    }
  }
}
