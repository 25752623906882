/******************************************************************************************/
/* Typography
/* This file is use to store the typography.
/******************************************************************************************/

html {
  font-size: $font-size;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

h1 {
  font-family: $libre-baskerville;
  font-size: 2.5rem;
  margin: 1.5rem 0px;
  padding: 0;
  color: $mako;
  text-transform: capitalize;
}

h2 {
  font-family: $libre-baskerville;
  font-size: 2rem;
  margin: 1.5rem 0px;
  padding: 0;
  color: $mako;
  text-transform: capitalize;
}

h3 {
  font-family: $libre-baskerville;
  font-size: 1.75rem;
  margin: 1.5rem 0px;
  padding: 0;
  color: $mako;
  text-transform: capitalize;
}

h4 {
  font-family: $libre-baskerville;
  font-size: 1.5rem;
  margin: 1.5rem 0px;
  padding: 0;
  color: $mako;
  text-transform: capitalize;
}

h5 {
  font-family: $bitter;
  font-size: 1.25rem;
  margin: 1.5rem 0px;
  padding: 0;
  color: $mako;
  text-transform: capitalize;
}

h6 {
  font-family: $bitter;
  font-size: 1rem;
  margin: 1.5rem 0px;
  padding: 0;
  color: $mako;
  text-transform: capitalize;
}

p {
  font-family: $montserrat;
  font-size: 1rem;
  margin: 1rem 0px;
  padding: 0;
  color: $mako;
}

li {
  font-family: $bitter;
  font-size: 1.25rem;
  color: $mako;
}

a {
  font-family: $bitter;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 1px;
  color: $mako;
}

span {
  font-family: $bitter;
  color: $mako;
  font-size: 1rem;
  text-transform: capitalize;
}
