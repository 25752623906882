/******************************************************************************************/
/* Property Column Layout
/* This file is use to store the property column layout
/******************************************************************************************/

.property-column-layout {
  width: 100%;
  height: 100%;
  background-color: rgba($color: $white, $alpha: 0.75);
  padding: 0px 0px 30px 0px;

  @media #{$medium-device} {
    padding: 0px 0px 60px 0px;
  }

  .title-block {
    background-color: transparent;

    &--container {
      padding-bottom: 0px;
    }
  }

  &--container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  &__layout {
    @media #{$medium-device} {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    &__item {
      width: 100%;
      position: relative;
      overflow: hidden;
      height: auto;
      margin-top: 5px;

      @media #{$medium-device} {
        width: 50%;
      }

      @media #{$extra-large-device} {
        width: 33%;
      }
    }
  }

  .card {
    cursor: pointer;
    width: calc(100% - 25px);
    padding-top: 215px;
    margin-top: 25px;
    display: flex;
    margin-left: 5px;
    margin-bottom: 40px;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    height: auto;
    background-color: $white;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    transition: opacity 0.25s ease-out;

    @media #{$small-device} {
      padding-top: 305px;
    }

    @media #{$medium-device} {
      padding-top: 220px;
    }

    &:hover,
    &:focus {
      transition: opacity 0.25s ease-in;
      opacity: 0.85;
    }

    &__floating-image {
      position: absolute;
      width: 100%;
      width: calc(100% - 25px);
      top: 0px;
      right: 0px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      min-height: 228px;
      padding-left: 5px;
      padding-bottom: 5px;

      @media #{$small-device} {
        height: 318px;
      }

      @media #{$medium-device} {
        height: 232px;
      }

      &__background {
        position: absolute;
        top: 13px;
        right: 13px;
        width: calc(100% - 15px);
        padding-left: 2px;
        padding-bottom: 2px;
        height: 225px;
        background-color: lighten($dingley, 25%);
        z-index: 2;
        margin-left: 5px;

        @media #{$small-device} {
          height: 315px;
        }

        @media #{$medium-device} {
          height: 230px;
        }

        &::before {
          content: "";
          top: -5px;
          right: -7px;
          position: absolute;
          width: calc(100% + 2px);
          height: 226px;
          background-color: lighten($dingley, 0%);
          z-index: 1;

          @media #{$small-device} {
            height: 316px;
          }

          @media #{$medium-device} {
            height: 231px;
          }
        }
      }

      img {
        width: calc(100% - 5px);
        height: 225px;
        z-index: 3;
        object-fit: cover;

        @media #{$small-device} {
          height: 315px;
        }

        @media #{$medium-device} {
          height: 230px;
        }
      }

      &__icon {
        position: absolute;
        top: 3px;
        right: 0px;
        background-color: $mako;
        height: 30px;
        width: 45px;
        z-index: 4;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top-left-radius: 5px;

        svg {
          height: 16px;
        }

        span {
          color: $white;
          font-family: $bitter;
        }
      }
    }

    &__title {
      //padding-top: 25px;
      margin: 25px 0px 0px 30px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 10px;
        left: -15px;
        top: 0px;
        transform: translateY(150%);
        height: 10px;
        border-radius: 50%;
      }
    }

    &__property-features {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: calc(100% - 50px);
      margin: 15px 0px 15px 25px;

      @media #{$large-device} {
        height: 100%;
        margin-bottom: 0px;
      }

      @media #{$extra-large-device} {
        align-items: flex-start;
      }

      &__title {
        width: 100%;
        text-align: center;
        color: $white;
        font-weight: 400;
        margin: 0px 0px 25px 0px;
        font-size: 1.5rem;

        @media #{$extra-large-device} {
          text-align: start;
        }
      }

      &__layout {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100px;
        width: 100%;

        @media #{$large-device} {
          width: 90%;
        }

        @media #{$extra-large-device} {
          width: 100%;
          justify-content: space-evenly;
        }
      }

      &__item {
        width: 30%;
        box-sizing: border-box;
        margin: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        @media #{$extra-large-device} {
          justify-content: center;
          text-align: center;
        }

        &--receptions {
          margin-left: 0px;

          @media #{$extra-large-device} {
            width: 85px;
            margin-right: 10px;
          }
        }

        &--bedrooms {
          @media #{$extra-large-device} {
            margin-left: 10px;
            margin-right: 12px;
            width: 75px;
          }
        }

        &--bath {
          margin-right: 0px;

          @media #{$extra-large-device} {
            margin-left: 10px;
            width: 85px;
          }
        }

        svg {
          width: 35px;
          height: 35px;
          color: $mako;
        }

        &__quantity {
          margin-left: 15px;
          color: $silver;
          font-size: 1.5rem;
          width: 10px;
          font-family: $bitter;
        }

        &__name {
          margin-top: 15px;
          width: 100%;
          color: $silver;
          font-size: 1rem;
          font-family: $bitter;
          text-align: center;

          @media #{$extra-large-device} {
            width: auto;
          }
        }
      }

      &__break {
        position: relative;
        width: 5px;
        height: 100%;

        &::before {
          content: "";
          position: absolute;
          width: 1px;
          height: 75%;
          background-color: lighten($tussock, 25%);
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    &--color-default {
      .card__floating-image__background {
        background-color: lighten($tawny-port, 35%);

        &::before {
          background-color: lighten($tawny-port, 10%);
        }
      }

      .card__title {
        &::before {
          background-color: $tawny-port;
        }
      }
    }

    // Stickney Meadows Properties
    // Caistor
    &--color-caistor {
      .card__floating-image__background {
        background-color: lighten($caistor, 25%);

        &::before {
          background-color: lighten($caistor, 10%);
        }
      }

      .card__title {
        &::before {
          background-color: $caistor;
        }
      }
    }

    // Casterton
    &--color-casterton {
      .card__floating-image__background {
        background-color: lighten($casterton, 25%);

        &::before {
          background-color: lighten($casterton, 10%);
        }
      }

      .card__title {
        &::before {
          background-color: $casterton;
        }
      }
    }

    // Kirton
    &--color-kirton {
      .card__floating-image__background {
        background-color: lighten($kirton, 25%);

        &::before {
          background-color: lighten($kirton, 10%);
        }
      }

      .card__title {
        &::before {
          background-color: $kirton;
        }
      }
    }

    // Marton
    &--color-marton {
      .card__floating-image__background {
        background-color: lighten($marton, 10%);

        &::before {
          background-color: lighten($marton, 0%);
        }
      }

      .card__title {
        &::before {
          background-color: $marton;
        }
      }
    }

    // Willoughby
    &--color-willoughby {
      .card__floating-image__background {
        background-color: lighten($willoughby, 25%);

        &::before {
          background-color: lighten($willoughby, 10%);
        }
      }

      .card__title {
        &::before {
          background-color: $willoughby;
        }
      }
    }

    // Stickney Chase Properties
    // Ancaster
    &--color-ancaster {
      .card__floating-image__background {
        background-color: lighten($ancaster, 25%);

        &::before {
          background-color: lighten($ancaster, 10%);
        }
      }

      .card__title {
        &::before {
          background-color: $ancaster;
        }
      }
    }

    // Coningsby
    &--color-coningsby {
      .card__floating-image__background {
        background-color: lighten($coningsby, 25%);

        &::before {
          background-color: lighten($coningsby, 10%);
        }
      }

      .card__title {
        &::before {
          background-color: $coningsby;
        }
      }
    }

    // Ludlow
    &--color-ludlow {
      .card__floating-image__background {
        background-color: lighten($ludlow, 25%);

        &::before {
          background-color: lighten($ludlow, 10%);
        }
      }

      .card__title {
        &::before {
          background-color: $ludlow;
        }
      }
    }
  }
}
