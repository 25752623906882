/******************************************************************************************/
/* Navigation Modal
/* This file is use to store the navigation modal.
/******************************************************************************************/

.navigation-modal {
  display: flex;
  position: fixed;
  z-index: $modals;
  top: 0px;
  right: 0px;
  background-color: $mako;
  width: 100vw;
  height: 0px;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  &.open {
    height: 100%;

    &.transition {
      transition: height 0.5s ease-in, opacity 1.25s ease-in;
    }

    .transition {
      transition: height 4s ease-in opacity 1.25s ease-in;
    }

    .navigation-modal__close {
      height: 70px;
      opacity: 1;
    }

    .navigation-modal__header {
      transition: height 0.5s ease-in, opacity 0.5s ease-out;
      height: 140px;
      z-index: 16;
      opacity: 1;

      a {
        height: auto;

        img {
          transition: height 0.5s ease-in, opacity 0.5s ease-out;
          height: 75px;
        }

        span {
          height: auto;
          margin-top: 15px;
          background: -webkit-linear-gradient(left, $copper, $rob-roy);
          background: -o-linear-gradient(right, $copper, $rob-roy);
          background: -moz-linear-gradient(right, $copper, $rob-roy);
          background: linear-gradient(to right, $copper, $rob-roy);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    .navigation-modal__content {
      height: auto;
    }
  }

  &--container {
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 140px;
  }

  &__close {
    position: absolute;
    z-index: $modals-close;
    top: 0;
    right: 0;
    height: 0px;
    width: 70px;
    opacity: 0;
  }

  &__header {
    background-color: rgba($color: $mako, $alpha: 1);
    position: fixed;
    top: 0;
    height: 0px;
    width: 100%;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: height 0.85s ease-in, opacity 0.85s ease-out;
    overflow: hidden;

    a {
      height: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &:hover,
      &:focus,
      &:active {
        img {
          opacity: 0.75;
        }

        span {
          color: $tussock;
        }
      }

      img {
        width: 75px;
        height: 0px;
        object-fit: contain;
        opacity: 1;
        transition: height 0.85s ease-out, opacity 0.25s ease-in;
      }

      span {
        transition: height 0.85s ease-in, color 0.25s ease-in;
        margin-top: 0px;
        height: 0px;
        color: $white;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-family: $libre-baskerville;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 0px;
    opacity: 1;
    width: 100%;
    color: white;

    @media #{$extra-large-device} {
      min-height: 650px;
    }

    & > .container {
      margin: auto auto;

      @media #{$extra-large-device} {
        height: 100%;
      }
    }
  }

  &__footer {
    background-color: rgba($color: $mako, $alpha: 1);
    height: 68px;
    bottom: 0;
    width: 100%;
    z-index: 10;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media #{$medium-device} {
      flex-direction: column-reverse;
      height: auto;
      align-items: center;
      justify-content: center;
    }

    @media #{$large-device} {
      //padding-bottom: 25px;
    }

    &__section {
      width: 100%;
      margin: 5px 0px 5px 0px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      text-align: center;

      @media #{$medium-device} {
        &:first-child {
          flex: 1;
        }

        &:last-child {
          flex: 2;
        }
      }

      &:last-child {
        display: none;

        @media #{$medium-device} {
          display: flex;
        }
      }

      &__link,
      &__socials {
        width: 50%;
        margin: 5px 0px;
      }

      &__link {
        & a {
          color: white;
          font-size: 1.35rem;
        }
      }

      &__socials {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $carousel-pink;

        @media #{$medium-device} {
          justify-content: center;
        }

        span {
          &.highlight {
            color: rgba($color: $carousel-pink, $alpha: 0.5);
            font-weight: 700;
            letter-spacing: 1px;
            margin-right: 15px;
          }
        }

        & a {
          margin: 7px 5px 0px 5px;
          color: inherit;

          & svg {
            width: 22px;
            height: 22px;
            transition: all 0.25s linear;

            &:hover,
            &:focus,
            &:active {
              color: $tussock;
            }
          }
        }
      }

      &__address,
      &__email,
      &__phone {
        margin: 10px;

        @media #{$large-device} {
          width: 33%;
          margin: 0px;
        }

        &:hover,
        &:focus,
        &:active {
          & a {
            span {
              &.text {
                transition: border-color 0.25s ease-in;
                border-bottom: 1px solid
                  rgba($color: $carousel-pink, $alpha: 0.5);
              }
            }
          }
        }

        & a {
          font-size: 1rem;
          color: $tussock;

          span {
            &.text {
              font-size: 1rem;
              color: $tussock;
              border-color: transparent;
              transition: border-color 0.25s ease-out;
            }
          }
        }

        span {
          &.highlight {
            color: rgba($color: $carousel-pink, $alpha: 0.5);
            font-weight: 700;
            letter-spacing: 1px;
          }
        }
      }

      &__address {
        @media #{$large-device} {
          order: 2;
        }
      }

      &__email {
        @media #{$large-device} {
          order: 3;
        }
      }

      &__phone {
        @media #{$large-device} {
          order: 1;
        }
      }
    }
  }

  &.transition {
    transition: height 0.75s ease-in, opacity 1.25s ease-in;
  }

  .transition {
    transition: height 0.75s ease-in opacity 5s ease-in;
  }
}
