/******************************************************************************************/
/* Colors
/* This file is use to store all the scss related to the colors.
/******************************************************************************************/

.color-pallet {
  width: 100%;
  height: 100%;
  background-color: rgba($color: $white, $alpha: 0.75);
  padding: 0px 0px 30px 0px;

  @media #{$medium-device} {
    padding: 0px 0px 60px 0px;
  }

  .title-block {
    background-color: transparent;

    &--container {
      padding-bottom: 0px;
    }
  }

  &__layout {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: center;
    align-items: center;
  }

  &__color {
    width: 100px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;

    &--tussock {
      background-color: $tussock;
    }

    &--laurel {
      background-color: $laurel;
    }

    &--dingley {
      background-color: $dingley;
    }

    &--rose-wood {
      background-color: $rose-wood;
    }

    &--mako {
      background-color: $mako;
    }

    p {
      color: $white;
      writing-mode: vertical-rl;
      text-orientation: upright;
    }
  }
}
