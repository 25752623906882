/******************************************************************************************/
/* Title Block CTA
/* This file is use to store the title block cta.
/******************************************************************************************/

.title-block {
  background-color: rgba($color: $white, $alpha: 0.75);
  width: 100%;
  height: 100%;
  padding: 5px 0px;

  @media #{$medium-device} {
    padding: 35px 0px;
  }

  &--container {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    &__sub-header,
    &__header,
    &__description,
    &__cta-buttons {
      display: flex;
      text-align: center;
      justify-content: center;

      @media #{$large-device} {
        width: 85%;
      }

      @media #{$extra-large-device} {
        width: 75%;
      }

      @media #{$extra-extra-large-device} {
        width: 65%;
      }
    }

    &__sub-header {
      span {
        font-size: 1.25rem;
        color: $tussock;
      }
    }

    &__header {
      margin-top: 10px;

      h2,
      h1 {
        margin: 0px;

        br {
          @media #{$small-device} {
            display: none;
          }
        }
      }
    }

    &__description {
      margin-top: 35px;

      p {
        &:first-child {
          margin-top: 0px;
        }
      }
    }

    &__cta-buttons {
      margin-top: 25px;
      height: auto;
    }
  }
}
