/******************************************************************************************/
/* Gallery Feed
/* This file is use to store the gallery feed 
/******************************************************************************************/

.gallery-feed {
  width: 100%;
  height: 100%;
  background-color: rgba($color: $white, $alpha: 0.75);
  padding: 0px 0px 30px 0px;

  @media #{$medium-device} {
    padding: 0px 0px 60px 0px;
  }

  .title-block {
    background-color: transparent;

    &--container {
      padding-bottom: 0px;
    }
  }

  &--container {
    padding-top: 30px;
  }

  &__layout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;

    &__item {
      width: 50%;
      height: 150px;
      padding: 8px;
      box-sizing: border-box;
      cursor: pointer;

      &:nth-child(1),
      &:nth-child(8n + 6),
      &:nth-child(8n + 9) {
        width: 70%;

        @media #{$large-device} {
          width: 25%;
        }

        @media #{$extra-large-device} {
          width: 20%;
        }
      }

      &:nth-child(4n + 3),
      &:nth-child(4n + 4) {
        width: 50%;

        @media #{$large-device} {
          width: 25%;
        }

        @media #{$extra-large-device} {
          width: 20%;
        }
      }

      &:nth-child(2),
      &:nth-child(8n + 5),
      &:nth-child(8n + 10) {
        width: 30%;

        @media #{$large-device} {
          width: 25%;
        }

        @media #{$extra-large-device} {
          width: 20%;
        }
      }

      @media #{$small-device} {
        height: 200px;
      }

      @media #{$medium-device} {
        height: 225px;
      }

      @media #{$large-device} {
        height: 250px;
        width: 25%;

        &:nth-child(1),
        &:nth-child(6n + 6),
        &:nth-child(6n + 7) {
          width: 50%;

          @media #{$extra-large-device} {
            width: 20%;
          }
        }
      }

      @media #{$extra-large-device} {
        width: 20%;

        &:nth-child(8n + 2),
        &:nth-child(8n + 7) {
          width: 40%;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-shadow: 0px 7px 10px 0px rgba($color: $mako, $alpha: 0.75);
      }
    }
  }
}
