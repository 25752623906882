/******************************************************************************************/
/* Site Column Layout
/* This file is use to store the site column layout
/******************************************************************************************/

.site-column-layout {
  width: 100%;
  height: 100%;
  background-color: rgba($color: $white, $alpha: 0.75);
  padding: 0px 0px 30px 0px;

  @media #{$medium-device} {
    padding: 0px 0px 60px 0px;
  }

  .title-block {
    background-color: transparent;

    &--container {
      padding-bottom: 0px;
    }
  }

  &--container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  &__layout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media #{$medium-device} {
      justify-content: center;
    }

    @media #{$extra-large-device} {
      align-items: center;
    }

    &__item {
      width: 100%;
      padding-bottom: 45px;
      box-sizing: border-box;

      @media #{$medium-device} {
        width: 50%;
      }

      @media #{$extra-large-device} {
        width: 30%;
        padding-bottom: 0px;
      }

      &:hover,
      &:focus,
      &:active {
        .card__header--container {
          &::before {
            transition: all 1s ease-in;
            width: calc($extra-small-m-w + ($extra-small-m-w / 3));
            left: -122px;

            @media #{$small-device} {
              left: -122px;
              width: calc($small + 40px);
            }

            @media #{$medium-device} {
              left: -10px;
              width: calc(100% + 10px);
            }

            @media #{$large-device} {
              left: -10px;
              width: calc(100%);
            }

            @media #{$extra-large-device} {
              left: 0px;
              width: calc(100%);
            }
          }
        }

        .card__header__content {
          span {
            transition: all 1s ease-in;
            color: $white;
          }

          svg {
            transition: all 1s ease-in;
            color: $white;
          }

          h3 {
            transition: all 1s ease-in;
            color: $white;
          }
        }
      }

      &:last-child {
        padding-bottom: 0px;

        @media #{$extra-large-device} {
          padding-bottom: 0px;
        }
      }

      &:nth-child(1) {
        @media #{$medium-device} {
          order: 3;
        }

        @media #{$extra-large-device} {
          order: 2;
        }

        .card {
          @media #{$medium-device} {
            padding-right: 0px;
          }

          @media #{$extra-large-device} {
            padding-left: 0px;
          }
        }
      }

      &:nth-child(2) {
        @media #{$medium-device} {
          order: 1;
        }

        @media #{$extra-large-device} {
          order: 1;
        }

        .card {
          @media #{$medium-device} {
            padding-right: 8px;
          }

          @media #{$extra-large-device} {
            padding-right: 15px;
          }
        }
      }

      &:nth-child(3) {
        @media #{$medium-device} {
          padding-left: 8px;
          order: 2;
        }

        @media #{$extra-large-device} {
          order: 3;
          padding-left: 15px;
        }
      }

      .card {
        text-align: left;

        &__title {
          text-align: left;
          height: 55px;
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          h3 {
            margin: 0px;
          }
        }

        &__header {
          width: 100%;
          text-align: left;

          &--container {
            display: block;
            box-sizing: border-box;
            text-align: left;
            width: auto;
            position: relative;
            height: 95px;

            @media #{$large-device} {
              height: 95px;
            }

            &::before {
              content: "";
              top: 0px;
              left: 0px;
              z-index: 0;
              position: absolute;
              transition: all 1s ease-out;
              background-color: $tussock;
              width: 0px;
              transform: translateX(0px);
              height: 110px;

              @media #{$large-device} {
                height: 110px;
              }

              @media #{$extra-extra-large-device} {
                height: 95px;
              }
            }
          }

          &__content {
            position: absolute;
            border-left: 8px solid $tussock;
            padding-left: 8px;
            height: 100%;
            width: 100%;
            top: 0px;
            left: 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            height: 110px;

            @media #{$large-device} {
              height: 110px;
            }

            @media #{$extra-extra-large-device} {
              height: 95px;
            }

            span {
              transition: all 1s ease-out;
              font-size: 1.25rem;
            }

            h3 {
              transition: all 1s ease-out;
              margin: 0px;
              margin-top: 10px;

              br {
                @media #{$extra-extra-large-device} {
                  display: none;
                }
              }
            }
          }
        }

        &__image {
          height: 400px;
          margin-top: 20px;
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);

          @media #{$medium-device} {
            height: 250px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &__cta-buttons {
          height: auto;
          padding: 15px 0px 25px 0px;
        }

        &__location {
          display: flex;
          flex-direction: row;
          align-items: center;
          text-align: center;
          padding: 15px 0px 15px 0px;

          svg {
            transition: all 1s ease-out;
            color: $tussock;
            width: 25px;
            height: 25px;
            margin-right: 10px;
          }

          span {
            color: $boulder;
            font-weight: 700;
            font-size: 1.25rem;
          }
        }

        &__status {
          display: flex;
          flex-direction: row;
          align-items: center;
          text-align: center;
          padding: 0px 0px;

          svg {
            color: $tussock;
            width: 20px;
            height: 20px;
            margin-left: 12px;
          }

          span {
            color: $mako;
            font-size: 1.25rem;
          }
        }
      }

      &--feature {
        @media #{$medium-device} {
          width: 50%;
        }

        @media #{$extra-large-device} {
          width: 40%;
        }

        .card {
          &__image {
            @media #{$extra-large-device} {
              height: 375px;
              box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.35);
            }
          }
        }
      }

      &--stickney-meadows {
        .card {
          &__header {
            &--container {
              &::before {
                background-color: $stickney-meadows;
              }
            }

            &__content {
              border-left: 8px solid $stickney-meadows;
            }
          }

          &__status {
            svg {
              color: $stickney-meadows;
            }
          }

          &__location {
            svg {
              color: $stickney-meadows;
            }
          }
        }
      }

      &--stickney-chase {
        .card {
          &__header {
            &--container {
              &::before {
                background-color: $stickney-chase;
              }
            }

            &__content {
              border-left: 8px solid $stickney-chase;
            }
          }

          &__status {
            svg {
              color: $stickney-chase;
            }
          }

          &__location {
            svg {
              color: $stickney-chase;
            }
          }
        }
      }
    }
  }
}
