/******************************************************************************************/
/* Information Column Layout
/* This file is use to store the infomation column layout
/******************************************************************************************/

.information-column-layout {
  width: 100%;
  height: 100%;
  background-color: rgba($color: $white, $alpha: 0.75);
  padding: 0px 0px 30px 0px;

  @media #{$medium-device} {
    padding: 0px 0px 60px 0px;
  }

  .title-block {
    background-color: transparent;

    &--container {
      padding-bottom: 0px;
    }
  }

  &--container {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  &__layout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media #{$medium-device} {
      justify-content: center;
    }

    @media #{$extra-large-device} {
      align-items: center;
    }

    &__item {
      width: 100%;
      padding: 10px 0px;
      height: 300px;

      @media #{$small-device} {
        padding: 15px 0px;
      }

      @media #{$medium-device} {
        box-sizing: border-box;
        width: 50%;
      }

      @media #{$extra-large-device} {
        width: 30%;
        padding: 0px 0px;
      }

      &:nth-child(1) {
        @media #{$medium-device} {
          padding-right: 15px;
        }

        @media #{$extra-large-device} {
          width: 25%;
          height: 300px;
          padding-left: 0px;
          padding-right: 8px;
        }
      }

      &:nth-child(2) {
        @media #{$medium-device} {
          padding-left: 15px;
        }

        @media #{$extra-large-device} {
          width: 30%;
          height: 350px;
          padding-left: 8px;
          padding-right: 8px;
        }
      }

      &:nth-child(3) {
        @media #{$medium-device} {
          padding-right: 15px;
        }

        @media #{$extra-large-device} {
          padding-right: 0px;
          width: 25%;
          height: 300px;
          padding-left: 8px;
          padding-right: 8px;
        }
      }

      &:nth-child(4) {
        @media #{$medium-device} {
          padding-left: 15px;
        }

        @media #{$extra-large-device} {
          width: 20%;
          height: 250px;
          padding-left: 8px;
          padding-right: 0px;
        }
      }

      .card {
        height: 100%;
        box-shadow: 0px 7px 15px 0px rgba($color: $mako, $alpha: 1);
        background-color: rgba($color: $mako, $alpha: 1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__figure {
          width: 100%;
          padding: 25px 0px;
          position: relative;

          span {
            color: $tussock;
            font-size: 4rem;
            font-family: $libre-baskerville;
          }

          &::before {
            content: "";
            position: absolute;
            width: 15%;
            height: 1px;
            background-color: $white;
            left: 42.5%;
            bottom: 0px;
          }
        }

        &__text {
          padding: 25px 0px;
          width: 92%;

          @media #{$small-device} {
            width: 90%;
          }

          @media #{$medium-device} {
            width: 85%;
          }

          h3,
          h2 {
            font-weight: 400;
            color: $gallery;
            font-family: $montserrat;
            margin: 0px;
            font-size: 1.1rem;

            @media #{$extra-large-device} {
              font-size: 1rem;
            }
          }
        }
      }
    }

    &--small-text {
      .card {
        &__figure {
          span {
          }
        }

        &__text {
          h3,
          h2 {
          }
        }
      }
    }
  }

  &--bg-dingley {
    .information-column-layout__layout__item {
      .card {
        background-color: rgba($color: $dingley, $alpha: 1);
        box-shadow: 0px 7px 15px 0px rgba($color: $dingley, $alpha: 1);
      }
    }
  }
}
