/******************************************************************************************/
/* Hero Slider
/* This file is use to store the hero slide 
/******************************************************************************************/

.hero {
  padding-bottom: 0px;
  //padding-top: 70px;

  @media #{$large-device} {
    padding-bottom: 0px;
    padding-top: 0px;
  }

  &__slider {
    background-color: rgba($color: $white, $alpha: 0.75);

    &--container {
      overflow: hidden;
      position: relative;
      min-height: 600px;
      height: 100%;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);

      @media #{$large-device} {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        box-shadow: none;
        height: calc(100vh - 109px);
        min-height: 700px;
        background-color: transparent;
      }
    }

    &__slides {
      height: 100%;
      //transform: none !important;
      display: flex;

      &__slide {
        //display: none;
        width: 100%;
        transition: all 1s ease;
        display: flex;
        min-height: 600px;
        height: 100%;

        @media #{$large-device} {
          min-height: 700px;
          height: calc(100vh - 109px);
        }

        &--container {
          height: auto;
          position: relative;
          height: 100%;
          width: 100%;
        }

        img {
          height: 250px;
          width: 100%;
          object-fit: cover;

          @media #{$small-device} {
            height: 350px;
          }

          @media #{$large-device} {
            height: 100%;
          }

          &.bottom {
            object-position: bottom center;
          }

          &.top {
            object-position: top center;
          }
        }

        &__hero-card {
          width: 100%;
          min-height: 350px;

          @media #{$large-device} {
            height: calc(100vh - 109px);
            background-color: transparent;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            bottom: 0;
            top: 0;
            position: absolute;
            min-height: 600px;
            height: 100%;
          }

          &--container {
            background-color: transparent;
            padding-top: 25px;
            padding-bottom: 25px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            position: relative;

            @media #{$large-device} {
              position: absolute;
              background-color: rgba($color: $white, $alpha: 0.9);
              left: 8.5%;
              width: 425px;
              padding-top: 40px;
              padding-bottom: 40px;
              padding-left: 25px;
              padding-right: 25px;
              min-height: 325px;
            }
          }

          &__title,
          &__description,
          &__cta-buttons {
            display: flex;
            text-align: center;
          }

          &__title {
            padding-top: 10px;
            width: 100%;
            text-align: center;

            @media #{$large-device} {
              padding-top: 0px;
            }

            h1 {
              width: 100%;
              margin-top: 0px;
              font-size: 2.5rem;
            }
          }

          &__cta-buttons {
            height: auto;
          }

          &__sites {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;

            &__item {
              margin: 25px 15px 0px 15px;
              position: relative;

              @media #{$large-device} {
                margin: 35px 15px 0px 15px;
              }

              &:hover,
              &:focus,
              &:active {
                img {
                  transition: opacity 0.5s ease-in;
                  opacity: 0.75;
                }
              }

              img {
                width: 125px;
                height: 125px;
                object-fit: cover;
                transition: opacity 0.5s ease-out;
              }

              .hero__slider__slides__slide__hero-card__floating-cta {
                top: -35px;
                bottom: auto;

                @media #{$large-device} {
                  top: -40px;
                }

                &.left {
                  left: -30px;
                  right: auto;

                  @media #{$large-device} {
                    left: -35px;
                  }
                }

                &.right {
                  right: -30px;
                  left: auto;

                  @media #{$large-device} {
                    right: -35px;
                  }
                }
              }
            }
          }

          &__floating-logo {
            position: absolute;
            top: -70px;
            right: 34px;
            width: 100px;
            height: 100px;

            @media #{$large-device} {
              top: auto;
              bottom: -115px;
              left: 34px;
              width: 150px;
              height: 150px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          &__floating-cta {
            position: absolute;
            border-radius: 50%;
            width: 65px;
            height: 65px;
            top: 0px;
            right: 5px;
            background-color: $mako;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            transition: all 0.35s linear;

            @media #{$large-device} {
              top: auto;
              bottom: -6px;
              left: 0px;
              width: 75px;
              height: 75px;
            }

            &:focus,
            &:hover,
            &:active {
              background-color: transparent;

              a {
                color: $mako;
                border: 1px solid $mako;
              }
            }

            a {
              height: 100%;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              font-family: $montserrat;
              font-size: 0.45rem;
              font-weight: 600;
              letter-spacing: 2px;
              color: $white;
              text-transform: uppercase;
              border: 1px solid transparent;
              border-radius: 50%;
              transition: all 0.35s linear;

              @media #{$large-device} {
                margin-bottom: 0px;
                font-size: 0.55rem;
              }
            }
          }
        }

        &.floaters-left {
          .hero__slider__slides__slide__hero-card__floating-logo {
            @media only screen and (max-width: #{$extra-small}) {
              left: 34px;
              right: auto;
            }
          }

          .hero__slider__slides__slide__hero-card__floating-cta {
            @media only screen and (max-width: #{$extra-small}) {
              left: 5px;
              right: auto;
            }
          }
        }
      }
    }

    &__navigation-controls {
      display: none;
      width: 60px;
      height: 60px;
      background-color: rgba($color: $white, $alpha: 0.65);
      border-radius: 50%;

      &:hover {
        svg {
          opacity: 0.65;
        }
      }

      &--previous,
      &--next {
        @media #{$large-device} {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          position: absolute;
        }

        svg {
          position: absolute;
          width: 40px;
          height: 40px;
          color: $mako;
        }
      }

      &--previous {
        bottom: 50%;
        left: 1%;
      }

      &--next {
        bottom: 50%;
        right: 1%;
      }
    }

    .tns-visually-hidden {
      display: none;
    }

    .tns-slide-active {
      display: flex;
      min-height: 600px;
      height: 100%;

      @media #{$large-device} {
        height: calc(100vh - 109px);
        min-height: 700px;
      }
    }

    .tns-ovh {
      height: 100% !important;
    }

    .tns-nav {
      display: flex;
      flex-direction: row;
      justify-content: center;

      @media #{$large-device} {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
      }

      button {
        margin: 10px 5px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        padding: 6px;
        border: none;
        border: solid 1px $tussock;
        cursor: pointer;
      }

      .tns-nav-active {
        background-color: $tussock;
      }
    }
  }
}
