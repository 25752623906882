/******************************************************************************************/
/* Property Types Slider
/* This file is use to store the property types slider.
/******************************************************************************************/

.property-types-slider {
  background-color: rgba($color: $white, $alpha: 0.75);
  width: 100%;
  height: 100%;
  padding: 30px 0px;

  @media #{$medium-device} {
    padding: 60px 0px;
  }

  &:hover,
  &:focus,
  &:active {
    .property-types-slider__header--container {
      &::before {
        transition: all 1s ease-in;
        width: calc(375px);
        left: -122px;

        @media #{$small-device} {
          left: -122px;
          width: calc($small);
        }

        @media #{$medium-device} {
          left: -237px;
          width: calc($medium - 75px);
        }

        @media #{$large-device} {
          left: -130px;
          width: calc($large - 420px);
        }

        @media #{$extra-large-device} {
          left: -145px;
          width: calc($extra-large - 600px);
        }

        @media #{$extra-extra-large-device} {
          left: -50%;
          width: calc($extra-extra-large - 200px);
        }
      }
    }

    .property-types-slider__header__content {
      span {
        transition: all 1s ease-in;
        color: $white;
      }

      h2 {
        transition: all 1s ease-in;
        color: $white;
      }
    }

    .property-types-slider__main-slider__slides__slide__floating-site {
      svg {
        @media #{$large-device} {
          transition: opacity 0.75s ease-in;
          opacity: 0.5;
        }
      }

      .property-types-slider__main-slider__slides__slide__floating-cta {
        &:after {
          @media #{$large-device} {
            transition: opacity 0.75s ease-in;
            opacity: 0.35;
          }
        }
      }
    }
  }

  &--container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  &__header {
    width: 100%;
    overflow-x: hidden;

    &--container {
      display: block;
      box-sizing: border-box;
      text-align: left;
      width: auto;
      position: relative;
      height: 140px;

      @media #{$small-device} {
        height: 95px;
      }

      &::before {
        content: "";
        top: 0px;
        left: 0px;
        z-index: 0;
        position: absolute;
        transition: all 1s ease-out;
        background-color: $tussock;
        width: 0px;
        transform: translateX(0px);
        height: 130px;

        @media #{$small-device} {
          height: 95px;
        }
      }
    }

    &__content {
      position: absolute;
      border-left: 8px solid $tussock;
      padding-left: 8px;
      height: 100%;
      width: 100%;
      top: 0px;
      left: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 130px;

      @media #{$small-device} {
        height: 95px;
      }

      span {
        transition: all 1s ease-out;
        font-size: 1.25rem;
      }

      h2 {
        transition: all 1s ease-out;
        margin: 0px;
        margin-top: 10px;

        br {
          @media #{$small-device} {
            display: none;
          }
        }
      }
    }

    &--stickney-meadows {
      .property-types-slider {
        &__header {
          &--container {
            &::before {
              background-color: $stickney-meadows;
            }
          }

          &__content {
            border-color: $stickney-meadows;
          }
        }
      }
    }

    &--stickney-chase {
      .property-types-slider {
        &__header {
          &--container {
            &::before {
              background-color: $stickney-chase;
            }
          }

          &__content {
            border-color: $stickney-chase;
          }
        }
      }
    }
  }

  &__content {
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  &__main-slider {
    height: 100%;
    width: 100%;

    .tns-liveregion {
      display: none;
    }

    &--container {
      overflow: hidden;
      position: relative;
    }

    &__slides {
      width: 100%;
      display: flex;
      flex-direction: row;
      height: 100%;
      box-sizing: border-box;

      &__slide {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0px 1px 0px 1px;

        @media #{$large-device} {
          flex-direction: row;
          justify-content: end;
          height: 375px;
        }

        @media #{$extra-large-device} {
          height: 500px;
        }

        @media #{$extra-extra-large-device} {
          height: 500px;
        }

        &__image {
          min-height: 225px;
          max-height: 225px;
          height: 100%;
          width: 100%;
          display: flex;

          @media #{$small-device} {
            min-height: 275px;
            max-height: 275px;
          }

          @media #{$medium-device} {
            min-height: 325px;
            max-height: 325px;
          }

          @media #{$large-device} {
            min-height: none;
            max-height: none;
            display: flex;
            height: 100%;
            width: 50%;
            align-items: flex-end;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            @media #{$large-device} {
              flex-direction: row;
              height: 375px;
            }

            @media #{$extra-large-device} {
              height: 500px;
            }

            @media #{$extra-extra-large-device} {
              height: 500px;
            }
          }
        }

        &__content {
          width: 100%;
          display: flex;
          flex-direction: column;
          text-align: center;
          align-items: center;
          justify-content: center;
          background-color: rgba($color: $tussock, $alpha: 0);
          padding: 60px 0px 0px 0px;
          height: 100%;

          @media #{$small-device} {
          }

          @media #{$large-device} {
            width: 50%;
            justify-content: center;
            background-color: transparent;
            justify-content: end;
            padding: 0px 0px;
          }

          @media #{$extra-large-device} {
            justify-content: center;
          }

          &__sub-header,
          &__header,
          &__description,
          &__cta-buttons {
            display: flex;
            text-align: center;
            justify-content: center;
            width: 90%;

            @media #{$small-device} {
              width: 90%;
            }

            @media #{$medium-device} {
              width: 85%;
            }

            @media #{$large-device} {
              justify-content: start;
              text-align: start;
            }
          }

          &__sub-header {
            span {
              color: $mako;
              font-size: 1rem;

              @media #{$large-device} {
                color: $mako;
              }
            }
          }

          &__header {
            margin-top: 10px;

            h3 {
              margin: 0px;
              color: $mako;

              @media #{$large-device} {
                color: $mako;
              }

              br {
                @media #{$small-device} {
                  display: none;
                }
              }
            }
          }

          &__description {
            margin-top: 35px;

            p {
              color: $mako;

              @media #{$large-device} {
                color: $mako;
              }

              &:first-child {
                margin-top: 0px;
              }
            }
          }

          &__cta-buttons {
            margin-top: 25px;
            height: auto;
          }
        }

        &__floating-site {
          position: absolute;
          width: 100px;
          height: 100px;
          top: 170px;
          left: 0px;
          z-index: 5;
          background-color: rebeccapurple;

          @media #{$small-device} {
            top: 220px;
          }

          @media #{$medium-device} {
            top: 270px;
          }

          @media #{$large-device} {
            right: 0px;
            left: auto;
            top: 0px;
          }

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }

          svg {
            display: none;

            @media #{$large-device} {
              display: block;
              position: absolute;
              width: 25px;
              height: 25px;
              right: 65px;
              top: 125px;
              opacity: 0;
              transition: opacity 0.75s ease-out;
              color: $mako;
            }
          }
        }

        &__floating-cta {
          position: absolute;
          z-index: 2;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          display: block;
          background-color: $mako;
          justify-content: center;
          align-items: center;
          text-align: center;
          transition: all 0.35s linear;
          top: -25px;
          left: 75px;

          @media #{$large-device} {
            left: -29px;
            top: auto;
            bottom: -29px;
            width: 58px;
            height: 58px;
          }

          &:focus,
          &:hover,
          &:active {
            background-color: rgba($color: $mako, $alpha: 0.65);
          }

          &:after {
            transition: opacity 0.75s ease-out;

            @media #{$large-device} {
              z-index: -1;
              background-color: transparent;
              border: 1px solid $mako;
              border-radius: 50%;
              content: "";
              height: 62px;
              right: -3px;
              opacity: 0;
              position: absolute;
              top: -3px;
              width: 62px;
            }
          }

          a {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-family: $montserrat;
            font-size: 0.6rem;
            font-weight: 600;
            letter-spacing: 2px;
            margin-bottom: 0px;
            margin-left: 1px;
            color: $white;
            text-transform: uppercase;

            @media #{$large-device} {
              font-size: 0.65rem;
            }
          }
        }

        &.tns-slide-active {
          opacity: 1;
          .property-types-slider__main-slider__slides__slide__floating-site {
            display: block;
          }

          .property-types-slider__main-slider__slides__slide__floating-cta {
            @media #{$large-device} {
              display: flex;
            }
          }
        }
      }
    }
  }

  .tns-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0px 0px 0px;

    @media #{$large-device} {
      justify-content: end;
    }

    button {
      margin: 10px 5px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      padding: 6px;
      border: none;
    }

    .tns-nav-active {
      background-color: $tussock;
    }
  }

  .tns-controls {
    bottom: 0px;
    left: 0px;
    position: absolute;
    display: none;
    flex-direction: row;
    justify-content: space-between;
    width: 100px;

    @media #{$large-device} {
      display: flex;
    }

    button {
      all: unset;
      width: 45px;
      height: 45px;
      background: none;
      background-color: rgba($color: $mako, $alpha: 1);
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.35s linear;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &:hover,
      &:focus {
        opacity: 0.65;
      }

      svg {
        object-fit: cover;
        width: 50%;
        height: 50%;
        color: $carousel-pink;
      }
    }
  }
}

.tns-ovh.tns-ah {
  height: auto !important;
}
