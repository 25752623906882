/******************************************************************************************/
/* Fonts
/* This file is use to store all the scss font imports.
/******************************************************************************************/

// Bitter Fonts
@font-face {
  font-family: "bitter";
  src: url("/assets/fonts/Bitter-Regular.ttf") format("truetype");
  font-display: swap;
}

// Libre Baskerville
@font-face {
  font-family: "libre-baskerville";
  src: url("/assets/fonts/LibreBaskerville-Regular.ttf") format("truetype");
  font-display: swap;
}

// Montserrat
@font-face {
  font-family: "montserrat";
  src: url("/assets/fonts/Montserrat-Regular.ttf") format("truetype");
  font-display: swap;
}
