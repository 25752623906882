/******************************************************************************************/
/* Contact Details Sidebar
/* This file is use to store the contact details sidebar.
/******************************************************************************************/

.contact-details-sidebar {
  z-index: $modals-close;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 600px;

  &--container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__content {
    height: 40px;
    position: absolute;
    top: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;

    &__label {
      color: white;
      text-align: center;

      &--one {
        width: 110px;
      }

      &--two {
        width: 225px;
      }
    }

    &__line {
      margin: 10px;
      height: 1px;
      background-color: white;

      &--one {
        width: 30px;
      }

      &--two {
        width: 65px;
      }

      &--three {
        width: 100px;
      }
    }
  }
}
