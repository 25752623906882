/******************************************************************************************/
/* Close Button
/* This file is use to store the close button
/******************************************************************************************/

.close-button {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    .close-button__close {
      &__line {
        transition: all 0.35s ease-in;

        &--1 {
          transform: rotate(0deg);
        }

        &--2 {
          transform: rotate(0deg);
        }
      }
    }
  }

  &--container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__close {
    width: 50%;
    height: 35%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__line {
      position: absolute;
      top: 50%;
      height: 15%;
      width: 100%;
      transform-origin: center center;
      transition: all 0.35s ease-out;
      background-color: white;

      &--1 {
        transform: rotate(45deg);
      }

      &--2 {
        transform: rotate(-45deg);
      }
    }
  }
}
