/******************************************************************************************/
/* Values column Layout
/* This file is use to store the values column layout
/******************************************************************************************/

.values-column-layout {
  width: 100%;
  height: 100%;
  background-color: rgba($color: $white, $alpha: 0.75);
  padding: 0px 0px 30px 0px;

  @media #{$medium-device} {
    padding: 0px 0px 60px 0px;
  }

  .title-block {
    background-color: transparent;

    &--container {
      padding-bottom: 0px;
    }
  }

  &--container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  &__layout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media #{$medium-device} {
      justify-content: center;
    }

    @media #{$extra-large-device} {
      align-items: center;
    }

    &__item {
      width: 100%;
      padding: 10px 0px;
      height: 300px;

      @media #{$small-device} {
        padding: 15px 0px;
      }

      @media #{$medium-device} {
        box-sizing: border-box;
        width: 50%;
      }

      @media #{$extra-large-device} {
        width: 30%;
        height: 350px;
      }

      &:nth-child(1) {
        @media #{$medium-device} {
          padding-right: 15px;
        }

        @media #{$extra-large-device} {
          padding-right: 0px;
        }
      }

      &:nth-child(2) {
        @media #{$medium-device} {
          padding-left: 15px;
        }

        @media #{$extra-large-device} {
          width: 40%;
          padding-right: 15px;
          height: 425px;
        }
      }

      &:nth-child(3) {
        @media #{$medium-device} {
          padding-right: 7.5px;
          padding-left: 7.5px;
        }

        @media #{$extra-large-device} {
          padding-right: 0px;
          padding-left: 0px;
        }
      }

      .card {
        height: 100%;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
        background-color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__icon,
        &__title,
        &__description {
          width: 92%;

          @media #{$small-device} {
            width: 90%;
          }

          @media #{$medium-device} {
            width: 85%;
          }
        }

        &__icon {
          svg {
            color: $tussock;
            width: 40px;
            height: 40px;
          }
        }

        &__title {
          margin: 15px 0px 25px 0px;

          h3 {
            margin: 0px;
          }
        }

        &__description {
          P,
          a {
            margin: 0px 0px 15px 0px;

            &:last-child {
              margin: 0px;
            }
          }

          a {
            text-decoration: none;
            font-family: $montserrat;
            font-size: 1rem;
            letter-spacing: 0px;

            &:hover,
            &:focus,
            &:active {
              text-decoration: underline;
              text-decoration-color: $tussock;
            }
          }
        }
      }
    }
  }
}
