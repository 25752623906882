/******************************************************************************************/
/* Lightbox Modal
/* This file is use to store the lightbox modal.
/******************************************************************************************/

.lightbox-modal {
  display: flex;
  position: fixed;
  z-index: $modals;
  top: 0px;
  right: 0px;
  background-color: rgba($color: $mako, $alpha: 0.9);
  width: 100vw;
  height: 0px;
  opacity: 0;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  transition: all 0.75s ease-out;
  justify-content: center;
  align-items: center;

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  &.open {
    height: 100%;
    opacity: 1;

    &.transition {
      transition: all 0.75s ease-in;
    }

    .lightbox-modal__image {
      opacity: 1;
      height: 90%;
      transition: all 0.75s ease-in;
    }

    .lightbox-modal__close {
      height: 70px;
      opacity: 1;
    }
  }

  &__close {
    position: absolute;
    z-index: $modals-close;
    top: 0;
    right: 0;
    height: 0px;
    width: 70px;
    opacity: 0;
  }

  &--container {
    max-height: 80%;
    z-index: 100;
    position: relative;
    height: 100%;
  }

  &__controls {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__previous,
    &__next {
      cursor: pointer;

      &:hover,
      &:focus {
        svg {
          color: rgba($color: $tussock, $alpha: 0.75);
        }
      }

      svg {
        color: $white;
        width: 35px;
        height: 35px;
      }
    }
  }

  &__dots {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 50%;

    &__dot {
      margin: 10px 5px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: buttonface;

      &.active {
        background-color: rgba($color: $tussock, $alpha: 1);
      }
    }
  }

  &__image {
    padding: 0px 0px 0px 0px;
    width: 100%;
    height: 0px;
    opacity: 0;
    box-sizing: border-box;
    transition: all 0.75s ease-out;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
