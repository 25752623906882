/******************************************************************************************/
/* Property Modal
/* This file is use to store the property modal.
/******************************************************************************************/

.property-modal {
  display: flex;
  position: fixed;
  z-index: $modals;
  top: 0px;
  right: 0px;
  background-color: rgba($color: $mako, $alpha: 1);
  width: 100vw;
  height: 0px;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  transition: height 0.6s ease-out;

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  &.open {
    height: 100%;
    transition: height 0.75s ease-in;

    .property-modal__close {
      height: 70px;
      opacity: 1;
    }
  }

  &__close {
    position: absolute;
    z-index: $modals-close;
    top: 0;
    right: 0;
    height: 0px;
    width: 70px;
    opacity: 0;
  }

  &__table {
    display: table;
    text-align: center;
    vertical-align: middle;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &__cell {
      position: relative;
      display: table-cell;
      vertical-align: middle;

      &--container {
        display: inline-block;
        height: 100%;
        margin: 0 auto;
        width: 100%;
      }

      &__content {
        display: flex;
        flex-direction: row;
        height: 100%;
        justify-content: center;
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  &__view {
    height: 0px;
    opacity: 0;
    transition: height 1s ease-out, opacity 0.2s ease-out;
    overflow: hidden;
    display: block;

    &.open {
      height: 100%;
      opacity: 1;
      margin: 30px 0px 30px 0px;
      overflow: visible;
      transition: height 0.75s ease-in, opacity 1s ease-in;

      @media #{$extra-large-device} {
        transition: height 0.45s ease-in, opacity 1s ease-in;
      }

      .property-modal__view--container {
        overflow: visible;
        height: 100%;
      }
    }

    &--container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      overflow: hidden;
    }

    &__layout {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media #{$extra-large-device} {
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;
        margin: auto 0px;
        align-items: normal;
        justify-content: flex-end;
      }
    }

    &__columns {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 30px;

      @media #{$extra-large-device} {
        padding-bottom: 0px;
        width: 50%;
      }

      &:last-child {
        padding-bottom: 0px;
        margin-top: 30px;
        align-items: flex-end;

        @media #{$extra-large-device} {
          align-items: center;
          width: 100%;
        }

        .card {
          @media #{$extra-large-device} {
            width: 100%;
          }
        }
      }

      &:first-child {
        @media #{$extra-large-device} {
          justify-content: flex-start;
          min-height: 570px;
        }
      }
    }

    &__slider-title {
      width: 100%;
      padding-bottom: 30px;

      @media #{$extra-large-device} {
        display: none;
      }

      .title-block {
        background-color: transparent;
        padding: 0px;

        &--container {
          padding-top: 0px;
          padding-bottom: 0px;
        }

        &__content {
          &__sub-header {
            span {
              color: $tussock;
            }
          }

          &__header {
            h2 {
              font-size: 2.25rem;
              color: $white;
            }
          }
        }
      }
    }

    &__main-slider {
      width: 100%;
      height: 100%;
      overflow: hidden;

      @media #{$extra-large-device} {
        height: auto;
      }

      &__slides {
        width: 100%;
        display: flex;
        flex-direction: row;
        height: 100%;
        box-sizing: border-box;

        &__slide {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 250px;
          margin-left: 2px;
          margin-right: 2px;

          @media #{$small-device} {
            height: 325px;
          }

          @media #{$medium-device} {
            height: 445px;
          }

          @media #{$large-device} {
            height: 575px;
          }

          @media #{$extra-large-device} {
            height: 345px;
          }

          @media #{$extra-extra-large-device} {
            height: 400px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: bottom;
          }

          &.force-fit {
            img {
              object-fit: contain;
            }
          }
        }
      }

      .tns-liveregion {
        display: none;
      }
    }

    &__controls {
      padding: 20px 0px 0px 0px;
      width: 100%;

      &__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      &__prev,
      &__next {
        border-radius: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.35s linear;

        &:hover,
        &:focus,
        &:active {
          svg {
            color: $tussock;
          }
        }

        svg {
          object-fit: cover;
          width: 15px;
          height: 15px;
          color: $carousel-pink;
          transition: all 0.35s linear;

          @media #{$small-device} {
            width: 25px;
            height: 25px;
          }

          @media #{$medium-device} {
            width: 30px;
            height: 30px;
          }

          @media #{$extra-extra-large-device} {
            width: 35px;
            height: 35px;
          }
        }
      }

      &__preview-slider {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        margin: 0px 10px;

        &__slides {
          display: flex;
          flex-direction: row;
          width: 100%;

          &__slide {
            display: flex;
            flex-direction: column;
            height: 50px;
            cursor: pointer;

            @media #{$small-device} {
              height: 70px;
            }

            @media #{$medium-device} {
              height: 100px;
            }

            @media #{$large-device} {
              height: 150px;
            }

            @media #{$extra-large-device} {
              height: 100px;
            }

            @media #{$extra-extra-large-device} {
              height: 95px;
            }

            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .tns-liveregion {
        display: none;
      }
    }

    &__social-share-links {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      margin-top: 30px;

      @media #{$extra-large-device} {
        justify-content: end;
      }

      span.highlight {
        font-family: $libre-baskerville;
        color: rgba($color: $tussock, $alpha: 1);
        font-weight: 400;
        letter-spacing: 0px;
        font-size: 1.25rem;
      }

      ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        li {
          padding: 0px 10px;
          height: 35px;

          a {
            svg {
              width: 30px;
              height: 30px;
              transition: all 0.25s linear;
              color: $white;

              &:hover,
              &:focus,
              &:active {
                color: $tussock;
              }
            }
          }
        }
      }
    }

    .card {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media #{$large-device} {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }

      @media #{$extra-large-device} {
        width: 90%;
        flex-direction: column;
        align-items: flex-end;
        flex-wrap: nowrap;
      }

      &__title {
        display: none;

        @media #{$extra-large-device} {
          display: block;
          padding-bottom: 30px;
          width: 100%;
        }

        .title-block {
          background-color: transparent;
          padding: 0px;

          &--container {
            padding-top: 0px;
            padding-bottom: 0px;
          }

          &__content {
            &__sub-header {
              @media #{$extra-large-device} {
                width: 100%;
                justify-content: flex-start;
                text-align: left;
              }

              span {
                color: $tussock;
              }
            }

            &__header {
              @media #{$extra-large-device} {
                width: 100%;
                justify-content: flex-start;
                text-align: left;
              }

              h2 {
                font-size: 2.25rem;
                color: $white;
              }
            }
          }
        }
      }

      &__price {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 5px;

        @media #{$large-device} {
          padding-bottom: 25px;
        }

        @media #{$extra-large-device} {
          align-items: flex-start;
        }

        span.text {
          font-size: 0.75rem;
          color: $white;
          margin-bottom: 5px;
        }

        span.guid-price {
          font-size: 2rem;
          color: $tussock;
          padding: 5px 10px;
          border: 1px solid $tussock;
        }
      }

      &__property-features {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-top: 25px;
        margin-bottom: 15px;

        @media #{$large-device} {
          height: 100%;
          width: 50%;
          margin-top: 15px;
          margin-bottom: 0px;
        }

        @media #{$extra-large-device} {
          align-items: flex-start;
          width: 100%;
        }

        &__title {
          width: 100%;
          text-align: center;
          color: $white;
          font-weight: 400;
          margin: 0px 0px 25px 0px;
          font-size: 1.5rem;

          @media #{$extra-large-device} {
            text-align: start;
          }
        }

        &__layout {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          height: 100px;
          width: 100%;

          @media #{$large-device} {
            width: 90%;
          }

          @media #{$extra-large-device} {
            width: auto;
          }
        }

        &__item {
          width: 30%;
          box-sizing: border-box;
          margin: 10px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;

          @media #{$extra-large-device} {
            justify-content: flex-start;
            text-align: start;
          }

          &--receptions {
            margin-left: 0px;

            @media #{$extra-large-device} {
              width: 100px;
            }
          }

          &--bedrooms {
            @media #{$extra-large-device} {
              margin-left: 20px;
              width: 100px;
            }
          }

          &--bath {
            margin-right: 0px;

            @media #{$extra-large-device} {
              margin-left: 20px;
              width: 100px;
            }
          }

          svg {
            width: 35px;
            height: 35px;
          }

          &__quantity {
            margin-left: 15px;
            color: $silver;
            font-size: 1.5rem;
            width: 10px;
            font-family: $bitter;
          }

          &__name {
            margin-top: 15px;
            width: 100%;
            color: $silver;
            font-size: 1rem;
            font-family: $bitter;

            @media #{$extra-large-device} {
              width: auto;
            }
          }
        }

        &__break {
          position: relative;
          width: 5px;
          height: 100%;

          &::before {
            content: "";
            position: absolute;
            width: 1px;
            height: 75%;
            background-color: lighten($tussock, 25%);
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      &__description {
        text-align: center;
        width: 100%;
        margin-top: 15px;

        @media #{$large-device} {
          width: 50%;
          margin-top: 15px;
          margin-bottom: 0px;
        }

        @media #{$extra-large-device} {
          width: 100%;
          text-align: start;
          margin-top: 30px;
        }

        &__title {
          width: 100%;
          font-size: 1.5rem;
          color: $white;
          font-weight: 400;
          margin: 0px 0px 25px 0px;

          @media #{$large-device} {
            width: 80%;
            margin: 0px auto 25px auto;
          }

          @media #{$extra-large-device} {
            width: 100%;
          }
        }

        p {
          margin: 0px;
          color: $silver;
          margin-top: 15px;

          @media #{$large-device} {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
          }

          @media #{$extra-large-device} {
            width: 100%;
          }

          &:last-child {
            margin-top: 15px;
          }

          &:first-child {
            margin-top: 0px;
          }
        }
      }

      &__property-details {
        text-align: center;
        width: 100%;
        //margin-top: 30px;
        margin-bottom: 15px;

        @media #{$large-device} {
          width: 100%;
          //margin-top: 30px;
          margin-bottom: 0px;
        }

        @media #{$extra-large-device} {
          width: 100%;
          text-align: start;
          //margin-top: 40px;
        }

        &__title {
          width: 100%;
          font-size: 1.5rem;
          color: $white;
          font-weight: 400;
          margin: 0px 0px 25px 0px;

          @media #{$large-device} {
            width: 80%;
            margin: 0px auto 25px auto;
          }

          @media #{$extra-large-device} {
            width: 100%;
          }
        }
      }

      &__register-cta {
        margin-top: 30px;
        padding-bottom: 10px;

        @media #{$large-device} {
          width: 100%;
        }

        @media #{$extra-large-device} {
          width: 100%;
          text-align: center;
          margin-top: 40px;
        }

        p {
          margin-top: 0px;
          margin-bottom: 20px;
          color: $white;
          font-weight: 400;
          font-family: $bitter;
        }

        .btn {
          font-weight: 400;
        }

        &--dt {
          display: none;

          @media #{$extra-large-device} {
            display: block;
            text-align: right;
          }
        }

        &--mob {
          @media #{$extra-large-device} {
            display: none;
          }
        }
      }
    }
  }
}
