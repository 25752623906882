/******************************************************************************************/
/* Gallery Slider
/* This file is use to store the gallery slider.
/******************************************************************************************/

.gallery-slider {
  background-color: rgba($color: $white, $alpha: 0.75);
  width: 100%;
  height: 100%;
  z-index: 0;
  padding: 30px 0px;

  @media #{$medium-device} {
    padding: 60px 0px;
  }

  &:hover,
  &:focus,
  &:active {
    .gallery-slider__header--container {
      &::before {
        transition: all 1s ease-in;
        width: calc(400px);
        left: -122px;

        @media #{$small-device} {
          left: -122px;
          width: calc($small);
        }

        @media #{$medium-device} {
          left: -237px;
          width: calc($medium - 75px);
        }

        @media #{$large-device} {
          left: -130px;
          width: calc($large - 420px);
        }

        @media #{$extra-large-device} {
          left: -145px;
          width: calc($extra-large - 600px);
        }

        @media #{$extra-extra-large-device} {
          left: -50%;
          width: calc($extra-extra-large - 200px);
        }
      }
    }

    .gallery-slider__header__content {
      span {
        transition: all 1s ease-in;
        color: $white;
      }

      h2 {
        transition: all 1s ease-in;
        color: $white;
      }
    }
  }

  &--container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  &__header {
    width: 100%;
    overflow-x: hidden;

    &--container {
      display: block;
      box-sizing: border-box;
      text-align: left;
      width: auto;
      position: relative;
      height: 140px;

      @media #{$small-device} {
        height: 95px;
      }

      &::before {
        content: "";
        top: 0px;
        left: 0px;
        z-index: 0;
        position: absolute;
        transition: all 1s ease-out;
        background-color: $tussock;
        width: 0px;
        transform: translateX(0px);
        height: 130px;

        @media #{$small-device} {
          height: 95px;
        }
      }
    }

    &__content {
      position: absolute;
      border-left: 8px solid $tussock;
      padding-left: 8px;
      height: 100%;
      width: 100%;
      top: 0px;
      left: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 130px;

      @media #{$small-device} {
        height: 95px;
      }

      span {
        transition: all 1s ease-out;
        font-size: 1.25rem;
      }

      h2 {
        transition: all 1s ease-out;
        margin: 0px;
        margin-top: 10px;

        br {
          @media #{$small-device} {
            display: none;
          }
        }
      }
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  &__main-slider {
    padding: 20px 0px;
    width: 100%;
    height: 100%;

    @media #{$large-device} {
      width: 760px;
    }

    @media #{$extra-large-device} {
      width: 1000px;
    }

    @media #{$extra-extra-large-device} {
      width: 1200px;
    }

    .tns-liveregion {
      display: none;
    }

    &__container {
      overflow: hidden;
    }

    &__slides {
      width: 100%;
      display: flex;
      flex-direction: row;
      height: 100%;
      box-sizing: border-box;

      &__slide {
        display: flex;
        align-items: end;
        justify-content: center;
        position: relative;
        width: 100%;

        @media #{$large-device} {
          padding-left: 5px;
          padding-right: 5px;
          height: 375px;
        }

        @media #{$extra-large-device} {
          height: 500px;
        }

        @media #{$extra-extra-large-device} {
          height: 600px;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 250px;

          @media #{$small-device} {
            height: 270px;
          }

          @media #{$medium-device} {
            height: 360px;
          }

          @media #{$large-device} {
            width: calc(100% - 100px);
            height: 325px;
          }

          @media #{$extra-large-device} {
            height: 450px;
          }

          @media #{$extra-extra-large-device} {
            height: 550px;
          }

          &.bottom {
            object-position: bottom center;
          }
        }

        &__floating-site {
          position: absolute;
          display: none;
          width: 100px;
          height: 100px;
          top: 0px;
          right: 0px;
          z-index: 5;

          @media #{$large-device} {
            right: 7px;
            top: 4px;
          }

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        &.tns-slide-active {
          opacity: 1;
          .gallery-slider__main-slider__slides__slide__floating-site {
            @media #{$large-device} {
              display: block;
            }
          }
        }
      }
    }
  }

  &__cta-button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;

    &.container {
      margin: 0px;
    }

    a {
      padding: 25px 0px;
      font-weight: 700;
      position: relative;
      margin-right: 25px;
      justify-content: center;

      &:hover,
      &:focus,
      &:active {
        svg {
          transition: all 0.32s ease-in;
          transform: translateX(10px);
        }
      }
    }

    svg {
      position: absolute;
      transition: all 0.32s ease-out;
      top: 25px;
      right: -40px;
      width: 25px;
      color: $mako;
    }
  }

  &__controls {
    padding: 0px 0px 20px 0px;

    &__container {
      position: relative;
    }

    &__directional {
      display: none;

      @media #{$large-device} {
        display: block;
        height: auto;
        position: absolute;
        top: 225px;
      }

      @media #{$extra-large-device} {
        top: 265px;
      }

      @media #{$extra-extra-large-device} {
        top: 350px;
      }

      &__container {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
      }

      &__prev,
      &__next {
        position: absolute;
        width: 60px;
        height: 60px;
        background-color: rgba($color: $mako, $alpha: 1);
        border-radius: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        cursor: pointer;
        transition: all 0.35s linear;

        &:hover,
        &:focus,
        &:active {
          opacity: 0.65;
        }

        svg {
          object-fit: cover;
          width: 40px;
          height: 40px;
          color: $carousel-pink;
        }
      }

      &__prev {
        left: 0px;
      }

      &__next {
        right: 0px;
      }
    }

    &__preview-slider {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      .tns-liveregion {
        display: none;
      }

      &__container {
        overflow: hidden;

        @media #{$large-device} {
        }
      }

      &__slides {
        display: flex;
        flex-direction: row;
        width: 100%;

        &__slide {
          display: flex;
          flex-direction: column;
          height: 75px;
          cursor: pointer;

          @media #{$small-device} {
            height: 90px;
          }

          @media #{$medium-device} {
            height: 100px;
          }

          @media #{$large-device} {
            height: 115px;
          }

          @media #{$extra-large-device} {
            height: 115px;
          }

          @media #{$extra-extra-large-device} {
            height: 160px;
          }

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;

            &.bottom {
              object-position: bottom center;
            }
          }

          p {
            margin-top: 10px;
            font-weight: 600;
          }

          &.tns-nav-active {
            //border: 2px solid red;
          }
        }
      }
    }
  }
}
