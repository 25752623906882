/*
This file is used to contain all modal imports.
Files inside this folder can contain specific modal related styles. 
*/

//Import modal files

// Navigation Modal
@import "./navigation";

@import "./lightbox";

@import "./property";

// Body modal reset
body {
  &.modal-open {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    min-height: 100%;
    /* mobile viewport bug fix */
    //min-height: -webkit-fill-available;

    .hamburger-button__hamburger {
      height: 0px;
    }
  }
}
