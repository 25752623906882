/******************************************************************************************/
/* CTA Buttons
/* This file is use to store the cta buttons
/******************************************************************************************/

.btn {
  padding: 0.5rem 1rem;
  text-align: center;
  transition: all 0.35s linear;

  &--bg-color-mako {
    background-color: $mako;
    color: $white;
    border: 1px solid $mako;

    &:focus,
    &:hover,
    &:active {
      background-color: transparent;
      color: $mako;
      border: 1px solid $mako;
    }
  }

  &--bg-color-laurel {
    background-color: $laurel;
    color: $white;
    border: 1px solid $laurel;

    &:focus,
    &:hover,
    &:active {
      background-color: transparent;
      color: $laurel;
      border: 1px solid $laurel;
    }
  }

  &--bg-color-bay-leaf {
    background-color: $bay-leaf;
    color: $white;
    border: 1px solid $bay-leaf;

    &:focus,
    &:hover,
    &:active {
      background-color: transparent;
      color: $bay-leaf;
      border: 1px solid $bay-leaf;
    }
  }

  &--bg-color-vista-blue {
    background-color: $vista-blue;
    color: $mako;
    border: 1px solid $vista-blue;

    &:focus,
    &:hover,
    &:active {
      background-color: transparent;
      color: $vista-blue;
      border: 1px solid $vista-blue;
    }
  }

  &--bg-color-carousel-pink {
    background-color: $carousel-pink;
    color: $mako;
    border: 1px solid $carousel-pink;

    &:focus,
    &:hover,
    &:active {
      background-color: transparent;
      color: $carousel-pink;
      border: 1px solid $carousel-pink;
    }
  }

  // Alternatives
  &--bg-color-mako-white {
    background-color: $mako;
    color: $white;
    border: 1px solid $mako;

    &:focus,
    &:hover,
    &:active {
      background-color: $white;
      color: $mako;
      border: 1px solid $mako;
    }
  }

  &--bg-color-mako-dingley {
    background-color: $mako;
    color: $white;
    border: 1px solid $mako;

    &:focus,
    &:hover,
    &:active {
      background-color: transparent;
      color: $white;
      border: 1px solid $white;
    }
  }

  &--bg-color-mako-tussock {
    background-color: $mako;
    color: $white;
    border: 1px solid $mako;

    &:focus,
    &:hover,
    &:active {
      background-color: transparent;
      color: $white;
      border: 1px solid $white;
    }
  }

  &--bg-color-transparent-tussock {
    background-color: $tussock;
    color: $white;
    border: 1px solid transparent;

    &:focus,
    &:hover,
    &:active {
      background-color: transparent;
      color: $tussock;
      border: 1px solid $tussock;
    }
  }

  &--bg-color-laurel-tussock {
    background-color: $laurel;
    color: $white;
    border: 1px solid $laurel;

    &:focus,
    &:hover,
    &:active {
      background-color: transparent;
      color: $white;
      border: 1px solid $tussock;
    }
  }
}

.btn-holder {
  padding-top: 60px;
  background-color: rgba($color: $white, $alpha: 0.75);
  padding-bottom: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .btn {
    width: 200px;
    margin: 15px;
  }
}
