/******************************************************************************************/
/* Variables
/* This file is use to store all the scss variables.
/******************************************************************************************/

// Assets Dir
$assets: "/assets";

// Resolution break points
$extra-small: 576px;
$small: 576px;
$medium: 768px;
$large: 992px;
$extra-large: 1200px;
$extra-extra-large: 1400px;

// Media queries with break points
$extra-extra-large-device: "only screen and (min-width: #{$extra-extra-large})";
$extra-large-device: "only screen and (min-width: #{$extra-large})";
$large-device: "only screen and (min-width: #{$large})";
$medium-device: "only screen and (min-width: #{$medium})";
$small-device: "only screen and (min-width: #{$small})";

// Main Container max-widths
$extra-small-m-w: 92%;
$small-m-w: 540px;
$medium-m-w: 720px;
$large-m-w: 960px;
$extra-large-m-w: 1140px;
$extra-extra-large-m-w: 1320px;

// Default Font Size
$font-size: 16px;

// Z-index order
$header: 5;
$modals: 10;

// Z-index order
$modals-close: 11;

// Theme Colors
$mako: #454851;
$tawny-port: #7d2248;
$sunglow: #ffcc33;
$tussock: #bc9c4c;
$rob-roy: #eccc6c;
$copper: #b98a3b;
$boulder: #757575;
$laurel: #73956f;
$bay-leaf: #7bae7f;
$vista-blue: #95d7ae;
$dingley: #617042;
$rose-wood: #610000;
$carousel-pink: #fceff9;
$white: #ffffff;
$black: #000000;
$gallery: #eeeeee;
$silver: #c7c7c7;

// Stickney Meadows Colors
$stickney-meadows: #0f7371;
$navenby: #d96d21;
$caistor: #2a2a78;
$casterton: #45b0c8;
$kirmington: #956c86;
$ancaster: #01602c;
$marton: #e7c0cc;
$kirton: #b0141f;
$willoughby: #e5b026;

// Stickney Chase Colors
$stickney-chase: #155464;
$ancaster: #ffb636;
$coningsby: #fd5356;
$ludlow: #4293ff;

// Fonts
$libre-baskerville: "libre-baskerville", sans-serif;
$bitter: "bitter", sans-serif;
$montserrat: "montserrat", sans-serif;
