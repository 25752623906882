/******************************************************************************************/
/* Accordion
/* This file is use to store the accordion
/******************************************************************************************/

.accordion-block {
  width: 100%;
  height: 100%;
  background-color: rgba($color: $white, $alpha: 0.75);
  padding: 15px 0px 15px 0px;

  @media #{$medium-device} {
    padding: 30px 0px 30px 0px;
  }

  &--container {
    width: 100%;
  }

  &__layout {
    width: 100%;

    .accordion-section {
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0px;
      }

      &__header {
        height: 100%;
        position: relative;
        padding: 15px 50px 15px 15px;
        background-color: rgba($color: $gallery, $alpha: 0.75);
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: $tussock;
          }

          .accordion-section {
            &__header {
              &__icon {
                &--plus {
                  svg {
                    color: $tussock;
                  }
                }
              }
            }
          }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          padding: 0px;
          margin: 0px;
          font-size: 1rem;
        }

        &__icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);

          &--plus {
            right: 0px;
            opacity: 1;
            transition: opacity 0.75s ease-out;
            margin-right: 15px;

            svg {
              transform: rotate(45deg);
            }
          }

          &--minus {
            right: 0px;
            opacity: 0;
            transition: opacity 0.75s ease-out;
            margin-right: 15px;
          }

          &__circle {
            width: 17px;
            height: 17px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background-color: $mako;
            border-radius: 50%;
          }

          svg {
            width: 11px;
            height: 11px;
            color: $white;
          }
        }
      }

      &__checkbox {
        &:checked {
          & ~ .accordion-section__header {
            &:hover,
            &:focus,
            &:active {
              .accordion-section {
                &__header {
                  &__icon {
                    &--minus {
                      svg {
                        color: $white;
                      }
                    }
                  }
                }
              }
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              color: $tussock;
            }

            .accordion-section {
              &__header {
                &__icon {
                  &--plus {
                    opacity: 0;

                    svg {
                      color: $tussock;
                    }
                  }

                  &--minus {
                    opacity: 1;

                    svg {
                      color: $tussock;
                    }
                  }
                }
              }
            }
          }

          & ~ .accordion-section__content {
            transition: height 0.5s ease-in;
          }
        }
      }

      &__content {
        height: 0px;
        transition: height 0.5s ease-out;
        overflow: hidden;

        &--container {
          padding: 15px 15px;
        }

        p {
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  &--property-modal {
    background-color: transparent;
    text-align: left;
    padding: 0px 0px 0px 0px;

    .accordion-block {
      &__layout {
        width: 100%;

        .accordion-section {
          width: 100%;
          box-sizing: border-box;
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0px;
          }

          &__header {
            height: 100%;
            position: relative;
            padding: 15px 50px 15px 15px;
            background-color: rgba($color: $gallery, $alpha: 0.1);
            cursor: pointer;

            &:hover,
            &:focus,
            &:active {
              h1,
              h2,
              h3,
              h4,
              h5,
              h6 {
                color: $tussock;
              }

              .accordion-section {
                &__header {
                  &__icon {
                    &--plus {
                      svg {
                        color: $tussock;
                      }
                    }
                  }
                }
              }
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              padding: 0px;
              margin: 0px;
              font-size: 1rem;
              color: $silver;
              font-weight: 400;
              font-family: $montserrat;
            }

            &__icon {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);

              &--plus {
                right: 0px;
                opacity: 1;
                transition: opacity 0.75s ease-out;
                margin-right: 15px;

                svg {
                  transform: rotate(45deg);
                }
              }

              &--minus {
                right: 0px;
                opacity: 0;
                transition: opacity 0.75s ease-out;
                margin-right: 15px;
              }

              &__circle {
                width: 17px;
                height: 17px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                background-color: $mako;
                border-radius: 50%;
              }

              svg {
                width: 11px;
                height: 11px;
                color: $white;
              }
            }
          }

          &__checkbox {
            &:checked {
              & ~ .accordion-section__header {
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                  color: $tussock;
                }

                .accordion-section {
                  &__header {
                    &__icon {
                      &--plus {
                        opacity: 0;

                        svg {
                          color: $tussock;
                        }
                      }

                      &--minus {
                        opacity: 1;

                        svg {
                          color: $tussock;
                        }
                      }
                    }
                  }
                }

                &:hover,
                &:focus,
                &:active {
                  .accordion-section {
                    &__header {
                      &__icon {
                        &--minus {
                          svg {
                            color: $white;
                          }
                        }
                      }
                    }
                  }
                }
              }

              & ~ .accordion-section__content {
                transition: height 0.5s ease-in;
              }
            }
          }

          &__content {
            height: 0px;
            transition: height 0.5s ease-out;
            border-bottom: 1px solid $tussock;

            &--container {
              padding: 15px 15px;
              overflow: hidden;
            }

            p {
              color: $silver;

              &:first-child {
                margin-top: 0px;
              }

              &:last-child {
                margin-bottom: 0px;
              }
            }

            &__cta-buttons {
              display: flex;
              height: 100%;
              flex-direction: column;

              @media #{$small-device} {
                flex-direction: row;
              }

              .btn {
                font-size: 1rem;

                &:last-child {
                  margin-top: 15px;

                  @media #{$small-device} {
                    margin-left: 15px;
                    margin-top: 0px;
                  }
                }

                &:first-child {
                  margin-top: 0px;
                  margin-left: 0px;
                }
              }
            }
          }
        }
      }
    }

    .table {
      display: flex;
      width: calc(88vw - 15px);
      overflow-x: auto;
      margin-bottom: 16px;

      @media #{$small-device} {
        width: 100%;
        max-width: none;
      }

      table {
        table-layout: fixed;
        border: none;
        background-color: $white;
        border-collapse: collapse;

        @media #{$small-device} {
          width: 100%;
        }

        thead {
          border-bottom: 1px solid $tussock;

          tr {
            height: 100%;
            overflow: hidden;

            th {
              min-width: 140px;
              max-width: 140px;
              padding: 15px 15px 8px 15px;
              font-size: 1rem;
              font-weight: 600;
              text-align: left;
              white-space: normal;
              font-family: $bitter;

              @media #{$small-device} {
                width: 33%;
                min-width: auto;
              }
            }
          }
        }

        tbody {
          tr {
            border-bottom: 1px solid $tussock;

            td {
              min-width: 140px;
              max-width: 140px;
              padding: 15px 15px 8px 15px;
              font-size: 1rem;
              font-weight: 600;
              text-align: left;
              white-space: normal;
              color: $boulder;
              font-family: $montserrat;

              @media #{$small-device} {
                width: 33%;
                min-width: auto;
              }

              &:last-child {
                padding: 8px 15px 15px 15px;
              }
            }
          }
        }

        .small-text {
          color: $tussock;
          font-size: 0.5rem;
          font-family: $montserrat;
          font-weight: 400;
        }
      }
    }
  }
}
