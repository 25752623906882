/******************************************************************************************/
/* Header
/* This file is use to store all the scss related to the Header.
/******************************************************************************************/

.header {
  background-color: rgba($color: $mako, $alpha: 1);
  width: 100%;
  height: auto;

  &--container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__socials-bar {
    display: none;
    width: 100%;
    height: 40px;
    background-color: $tawny-port;

    @media #{$large-device} {
      display: block;
    }

    &--container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
    }

    &__icons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px 3px;
        width: 26px;
        height: 26px;
        border: solid 1px transparent;
        border-radius: 50%;
        transition: all 0.25s linear;

        &:hover,
        &:focus,
        &:active {
          border: solid 1px $tussock;

          svg {
            color: $tussock;
          }
        }

        svg {
          color: $white;
          margin-right: 0px;
          width: 17px;
          height: 17px;
          transition: all 0.25s linear;
        }
      }
    }
  }

  &__main-bar {
    width: 100%;
    margin: 0px;
    height: 70px;
    z-index: $header;
    background-color: $mako;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;

    @media #{$large-device} {
      position: relative;
    }

    &--container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &__menu-icon {
      height: 70px;
      width: 70px;
    }

    &__title {
      display: none;

      @media #{$large-device} {
        display: block;
      }

      &:hover,
      &:active,
      &:focus {
        span {
          opacity: 0.75;
        }
      }

      span {
        transition: all 0.25s linear;
        font-size: 1.5em;
        text-transform: uppercase;
        font-family: $libre-baskerville;

        background: -webkit-linear-gradient(left, $copper, $rob-roy);
        background: -o-linear-gradient(right, $copper, $rob-roy);
        background: -moz-linear-gradient(right, $copper, $rob-roy);
        background: linear-gradient(to right, $copper, $rob-roy);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &__logo {
      height: 45px;
      width: 40px;
      margin: 0px 18px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &:hover,
      &:active,
      &:focus {
        img {
          opacity: 0.65;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 1;
        transition: opacity 0.25s linear;
      }

      span {
        display: none;
      }
    }

    &.sticky {
      position: fixed;
      top: 0;
      width: 100%;
    }
  }

  &--stickney-meadows {
    .header {
      &__socials-bar {
        background-color: $stickney-meadows;

        &__icons {
          a {
            border-color: transparent;

            &:hover,
            &:focus,
            &:active {
              border: solid 1px $tussock;

              svg {
                color: $tussock;
              }
            }

            svg {
              color: $white;
            }
          }
        }
      }
    }
  }

  &--stickney-chase {
    .header {
      &__socials-bar {
        background-color: $stickney-chase;

        &__icons {
          a {
            border-color: transparent;

            &:hover,
            &:focus,
            &:active {
              border: solid 1px $tussock;

              svg {
                color: $tussock;
              }
            }

            svg {
              color: $white;
            }
          }
        }
      }
    }
  }
}
