/******************************************************************************************/
/* Privacy Policy
/* This file is use to store the privacy policy page 
/******************************************************************************************/

.policy {
  &.container {
    padding: 30px 0px;

    @media #{$medium-device} {
      padding: 60px 0px;
    }
  }
}
