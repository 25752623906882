/******************************************************************************************/
/* Resets
/* This file is use to store the resets.
/******************************************************************************************/

html {
  height: -webkit-fill-available;
  margin: 0;
  padding: 0;

  @media #{$large-device} {
    min-height: 100%;
    height: 100%;
  }
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  margin: 0;
  padding: 0;
  background-image: url(#{$assets}/images/logos/lindensium-logo.optimised.png);
  background-repeat: repeat-y;
  background-size: 100% auto;

  @media #{$large-device} {
    min-height: 100%;
  }
}

main {
  position: relative;
  margin-top: -1px;
  background-color: rgba($color: $white, $alpha: 0.75);
  min-height: 100vh;
  min-height: -webkit-fill-available;
  
  @media #{$large-device} {
    min-height: 100%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

p {
  padding: 0;
  margin: 0;
}

img {
  width: 100%;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}
