/******************************************************************************************/
/* Hero
/* This file is use to store all the scss related to the hero.
/******************************************************************************************/

.hero-empty {
  padding-top: 70px;

  @media #{$large-device} {
    padding-top: 0px;
  }
}
