/******************************************************************************************/
/* Navigation Menu
/* This file is use to store the navigation menu.
/******************************************************************************************/

.navigation-menu-layout {
  padding: 35px 0px;

  @media #{$extra-large-device} {
    height: 100%;
  }

  &--container {
    height: 100%;
  }

  &__content {
    width: 100%;

    @media #{$medium-device} {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media #{$extra-large-device} {
      height: 85%;
      justify-content: space-evenly;
    }

    &__menu-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px 0px 20px 0px;
      cursor: pointer;

      &--developments {
        width: 100%;

        @media #{$medium-device} {
          width: 448px;
        }

        &:hover {
          @media #{$extra-large-device} {
            padding-bottom: 50px;
          }

          .navigation-menu-layout__content__menu-item__link {
            border-bottom: 2px solid $tussock;
          }

          @media #{$medium-device} {
            .navigation-menu-layout__content__menu-item__link {
              border-bottom: 2px solid $tussock;
            }
          }

          .navigation-menu-layout__content__menu-item__details__link {
            @media #{$extra-large-device} {
              height: 200px;
              transition: height 0.5s ease-in;
              border-width: 2px;
            }

            .menu-details-title {
              @media #{$extra-large-device} {
                transition: transform 0.5s ease-in;
                transition: opacity 1s ease-in;
                transform: translateY(75px);
                opacity: 1;
              }
            }

            img {
              @media #{$extra-large-device} {
                transition: height 0.5s ease-in;
                height: 150px;
              }
            }

            &:after,
            &::before {
              transition: all 1s !important;
            }
          }
        }
      }

      &--gallery {
        width: 100%;

        @media #{$medium-device} {
          width: 448px;
        }

        &:hover {
          @media #{$extra-large-device} {
            padding-bottom: 50px;
          }

          .navigation-menu-layout__content__menu-item__link {
            @media #{$extra-large-device} {
              border-bottom: 2px solid $tussock;
            }
          }

          @media #{$extra-large-device} {
            .navigation-menu-layout__content__menu-item__link {
              border-bottom: 2px solid $tussock;
            }
          }

          .navigation-menu-layout__content__menu-item__details__link {
            @media #{$extra-large-device} {
              height: 200px;
              transition: height 0.5s ease-in;
              border-width: 2px;
            }

            .menu-details-title {
              @media #{$extra-large-device} {
                transition: transform 0.5s ease-in;
                transition: opacity 1s ease-in;
                transform: translateY(75px);
                opacity: 1;
              }
            }

            img {
              @media #{$extra-large-device} {
                transition: height 0.5s ease-in;
                height: 185px;
              }
            }

            &:after,
            &::before {
              transition: all 1s !important;
            }
          }
        }
      }

      &:last-child {
        padding-bottom: 0px;
      }

      &__link {
        padding-bottom: 5px;
        transition: all 0.5s;
        border-bottom: 2px solid transparent;
        margin: 0px 0px 15px 0px;

        &:hover,
        &:focus,
        &:active {
          border-bottom-color: $tussock;
          a {
            .menu-title {
              color: $tussock;
            }
          }
        }

        a {
          height: 100%;
        }
      }

      .menu-title {
        color: $white;
        font-family: $libre-baskerville;
        font-size: 2.2rem;
        margin: 1.5rem 0px;
        padding: 0;
        transition: all 0.5s;

        @media #{$medium-device} {
          font-size: 1.8rem;
        }
      }

      .menu-details-title {
        color: $white;
        font-size: 1.25rem;
        font-family: $bitter;
        transition: all 0.5s;
      }

      &__details {
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        &--developments {
          display: flex;
          flex-direction: row;
          width: 100%;

          @media #{$medium-device} {
            display: flex;
            flex-direction: row;
            width: 100%;
          }

          .navigation-menu-layout__content__menu-item__details {
            &__link {
              width: 150px;
              height: 150px;
              background-color: transparent;
              padding: 0px;
              margin: 0px;
              display: block;
              float: left;
              position: relative;
              text-align: center;
              border-collapse: collapse;
              border: 2px solid $carousel-pink;
              text-decoration: none;
              margin: 0 10px;
              transition: all 1s;

              @media #{$medium-device} {
                width: 200px;
                height: 200px;
              }

              @media #{$extra-large-device} {
                transition: height 1s ease-out;
                //transition: border-width 1s ease-out;
                height: 0px;
                border-width: 0px;
              }

              &:before {
                transition: all 1s;
                position: absolute;
                content: "";
                width: 149px;
                height: 0%;
                left: calc(50% - 2px);
                margin-left: -50%;
                top: 50%;
                border-color: transparent;
                border-style: solid;
                border-width: 0 2px;

                @media #{$medium-device} {
                  width: 199px;
                }

                @media #{$extra-large-device} {
                  transition: all 0s !important;
                }
              }

              &:after {
                transition: all 1s;
                position: absolute;
                content: "";
                width: 0%;
                height: 150px;
                left: calc(50% - 2px);
                margin-left: -0%;
                top: -2px;
                border-color: transparent;
                border-style: solid;
                border-width: 2px 0;

                @media #{$medium-device} {
                  height: 200px;
                }

                @media #{$extra-large-device} {
                  transition: all 0s !important;
                }
              }

              &:hover {
                border-color: transparent;

                &:after {
                  width: 153px;
                  margin-left: -50%;
                  border-color: $tussock;

                  @media #{$medium-device} {
                    width: 203px;
                    margin-left: -50%;
                    border-color: $tussock;
                  }
                }

                &:before {
                  height: 152px;
                  top: 0%;
                  border-color: $tussock;

                  @media #{$medium-device} {
                    height: 202px;
                    top: 0%;
                    border-color: $tussock;
                  }
                }
              }

              &:first-child {
                margin-right: 10px;
              }

              &:last-child {
                margin-left: 10px;
              }

              a {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column-reverse;
                position: absolute;
                z-index: 15;

                .menu-details-title {
                  position: absolute;
                  bottom: 7px;

                  @media #{$medium-device} {
                    bottom: 15px;
                  }

                  @media #{$extra-large-device} {
                    opacity: 0;
                    bottom: auto;
                    top: auto;
                    transform: translateY(10px);
                    transition: opacity 0.5s ease-out;
                    transition: transform 0.5s ease-out;
                  }
                }
              }

              picture {
                //transition: all 1s ease-out;
                display: block;
                width: 100%;
                height: 100%;
                overflow: hidden;

                img {
                  transition: all 0.4s ease-out;
                  width: 92%;
                  height: 72%;
                  object-fit: cover;
                  opacity: 0.65;
                  margin-top: 8px;

                  @media #{$extra-large-device} {
                    height: 0px;
                  }
                }
              }
            }
          }
        }

        &--gallery {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          width: 100%;

          @media #{$extra-large-device} {
            display: flex;
            flex-direction: row;
            width: 100%;
          }

          .navigation-menu-layout__content__menu-item__details {
            &__link {
              @media #{$extra-large-device} {
                background-color: transparent;
                padding: 0px;
                display: block;
                float: left;
                position: relative;
                text-align: center;
                border-collapse: collapse;
                border: 2px solid $carousel-pink;
                text-decoration: none;
                margin: 0 10px;
                width: 200px;
                transition: height 1s ease-out;
                height: 0px;
                border-width: 0px;
              }

              &:before {
                @media #{$extra-large-device} {
                  transition: all 0s !important;
                  position: absolute;
                  content: "";
                  width: 199px;
                  height: 0%;
                  left: calc(50% - 2px);
                  margin-left: -50%;
                  top: 50%;
                  border-color: transparent;
                  border-style: solid;
                  border-width: 0 2px;
                }
              }

              &:after {
                @media #{$extra-large-device} {
                  transition: all 0s !important;
                  position: absolute;
                  content: "";
                  width: 0%;
                  left: calc(50% - 2px);
                  margin-left: -0%;
                  top: -2px;
                  border-color: transparent;
                  border-style: solid;
                  border-width: 2px 0;
                  height: 200px;
                }
              }

              &:hover {
                border-color: transparent;

                &:after {
                  width: 153px;
                  margin-left: -50%;
                  border-color: $tussock;

                  @media #{$extra-large-device} {
                    width: 203px;
                    margin-left: -50%;
                    border-color: $tussock;
                  }
                }

                &:before {
                  height: 152px;
                  top: 0%;
                  border-color: $tussock;

                  @media #{$extra-large-device} {
                    height: 202px;
                    top: 0%;
                    border-color: $tussock;
                  }
                }
              }

              &:first-child {
                @media #{$extra-large-device} {
                  margin-right: 10px;
                }
              }

              &:last-child {
                @media #{$extra-large-device} {
                  margin-left: 10px;
                }
              }

              a {
                @media #{$extra-large-device} {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column-reverse;
                  position: absolute;
                  z-index: 15;
                }

                .menu-details-title {
                  @media #{$extra-large-device} {
                    position: absolute;
                    bottom: 7px;
                    opacity: 0;
                    bottom: auto;
                    top: auto;
                    transform: translateY(10px);
                    transition: opacity 0.5s ease-out;
                    transition: transform 0.5s ease-out;
                  }
                }
              }

              picture {
                @media #{$extra-large-device} {
                  display: block;
                  width: 100%;
                  height: 100%;
                }

                img {
                  @media #{$extra-large-device} {
                    transition: all 0.4s ease-out;
                    width: 92%;
                    object-fit: cover;
                    opacity: 0.65;
                    margin-top: 8px;
                    height: 0px;
                  }
                }
              }
            }
          }
        }

        &__link {
          width: auto;
          padding-bottom: 10px;

          &:hover,
          &:focus,
          &:active {
            a {
              .menu-details-title {
                color: $tussock;
              }
            }
          }

          a {
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.5s;
          }

          picture {
            display: none;
          }
        }
      }
    }
  }
}
