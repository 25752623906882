/******************************************************************************************/
/* Hamburger Button
/* This file is use to store the hamburger button 
/******************************************************************************************/

.hamburger-button {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    .hamburger-button__hamburger {
      height: 27%;
      transition: height 0.15s ease-in;
    }
  }

  &--container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__hamburger {
    width: 50%;
    height: 35%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: height 0.15s ease-out;

    &__line {
      height: 15%;
      width: 100%;
      border-radius: 10px;
      background-color: white;
    }
  }
}
