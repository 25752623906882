/******************************************************************************************/
/* Container Columns
/* This file is use to store the container columns.
/******************************************************************************************/

.contained-columns-image-cta {
  background-color: rgba($color: $white, $alpha: 0.75);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &--container {
    max-width: $extra-small-m-w;
    width: 100%;

    @media #{$small-device} {
      min-width: $small;
    }

    @media #{$medium-device} {
      min-width: $medium;
    }

    @media #{$large-device} {
      min-width: $large;
    }

    @media #{$extra-large-device} {
      min-width: $extra-large;
    }

    @media #{$extra-extra-large-device} {
      min-width: $extra-extra-large;
    }
  }

  &__layout {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media #{$medium-device} {
      flex-direction: row;
      height: 100%;
    }

    &__column {
      &--image {
        height: 260px;

        @media #{$small-device} {
          height: 300px;
        }

        @media #{$medium-device} {
          flex: 1;
          height: auto;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &--content {
        background-color: rgba($color: $laurel, $alpha: 1);
        padding: 30px 0px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media #{$medium-device} {
          padding: 60px 0px;
          flex: 1;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;

      &__sub-header,
      &__header,
      &__description,
      &__cta-buttons {
        display: flex;
        text-align: center;
        justify-content: center;

        @media #{$medium-device} {
          width: 75%;
        }

        @media #{$large-device} {
          width: 60%;
        }

        @media #{$extra-large-device} {
          width: 50%;
        }

        @media #{$extra-extra-large-device} {
          width: 55%;
        }
      }

      &__sub-header {
        span {
          color: $white;
          font-size: 1.25rem;
        }
      }

      &__header {
        margin-top: 10px;

        h2 {
          margin: 0px;
          color: $white;

          br {
            @media #{$small-device} {
              display: none;
            }
          }
        }
      }

      &__description {
        margin-top: 35px;

        p {
          color: $white;

          &:first-child {
            margin-top: 0px;
          }
        }
      }

      &__cta-buttons {
        margin-top: 25px;
        height: auto;
      }
    }
  }

  &--bg-dingley {
    .contained-columns-image-cta__layout__column--content {
      background-color: rgba($color: $dingley, $alpha: 1);
    }
  }

  &--bg-white {
    .contained-columns-image-cta__layout__column--content {
      background-color: rgba($color: $white, $alpha: 1);
    }

    .contained-columns-image-cta__layout__content {
      &__sub-header,
      &__header,
      &__description,
      &__cta-buttons {
        span {
          color: $mako;
        }

        h2,
        h3 {
          color: $mako;
        }

        p {
          color: $mako;
        }
      }
    }
  }

  &--bg-tussock {
    .contained-columns-image-cta__layout__column--content {
      background-color: rgba($color: $tussock, $alpha: 1);
    }

    .contained-columns-image-cta__layout__content {
      &__sub-header,
      &__header,
      &__description,
      &__cta-buttons {
        span {
          color: $white;
        }

        h2,
        h3 {
          color: $white;
        }

        p {
          color: $white;
        }
      }
    }
  }

  &--bg-stickney-meadows {
    .contained-columns-image-cta__layout__column--content {
      background-color: rgba($color: $stickney-meadows, $alpha: 1);
    }

    .contained-columns-image-cta__layout__content {
      &__sub-header,
      &__header,
      &__description,
      &__cta-buttons {
        span {
          color: $white;
        }

        h2,
        h3 {
          color: $white;
        }

        p {
          color: $white;
        }
      }
    }
  }

  &--bg-stickney-chase {
    .contained-columns-image-cta__layout__column--content {
      background-color: rgba($color: $stickney-chase, $alpha: 1);
    }

    .contained-columns-image-cta__layout__content {
      &__sub-header,
      &__header,
      &__description,
      &__cta-buttons {
        span {
          color: $white;
        }

        h2,
        h3 {
          color: $white;
        }

        p {
          color: $white;
        }
      }
    }
  }
}
