/******************************************************************************************/
/* Hero Timeline
/* This file is use to store the hero timeline 
/******************************************************************************************/

.hero {
  padding-bottom: 0px;
  //padding-top: 70px;

  @media #{$large-device} {
    padding-bottom: 0px;
    padding-top: 0px;
  }

  &__timeline {
    &--container {
      overflow: hidden;
      position: relative;
    }

    &__layout {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      @media #{$large-device} {
        flex-direction: row;
      }
    }

    &__slider {
      width: 100%;
      height: 100%;
      background-color: rgba($color: $boulder, $alpha: 1);

      @media #{$large-device} {
        width: 100%;
      }

      &--container {
        overflow: hidden;
        position: relative;
        height: 100%;
      }

      &__slides {
        width: 100%;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        position: relative;
        min-height: 650px;
        height: 100%;

        @media #{$large-device} {
          height: calc(100vh - 109px);
          background-color: transparent;
        }

        &__slide {
          overflow: hidden;
          position: relative;

          &__background-image {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;

            &.bottom {
              img {
                object-position: bottom;
              }
            }

            img {
              top: 0px;
              right: 0px;
              width: 100%;
              height: 100%;
              object-fit: cover;
              opacity: 0.15;
            }
          }

          .card {
            width: 100%;
            position: absolute;
            top: 22%;
            margin: 0px auto;
            z-index: 2;
            top: 50%;
            transform: translateY(-60%);

            @media #{$large-device} {
              width: calc(75% - 45px);
              top: 50%;
              transform: translateY(-50%);
            }

            &__content {
              display: flex;
              flex-direction: column;
              text-align: center;
              align-items: center;

              @media #{$large-device} {
                align-items: flex-end;
              }
            }

            &__title,
            &__description,
            &__sub-title {
              display: flex;
              text-align: center;
              justify-content: center;

              @media #{$large-device} {
                width: 75%;
                text-align: right;
                justify-content: flex-end;
                align-items: flex-end;
              }

              @media #{$extra-large-device} {
                width: 65%;
              }

              @media #{$extra-extra-large-device} {
                width: 50%;
              }
            }

            &__title {
              display: flex;
              flex-direction: column-reverse;

              &__sub-heading {
                h2 {
                  color: $tussock;
                  font-weight: 400;
                  margin: 0px;
                  font-size: 1rem;

                  br {
                    @media #{$small-device} {
                      display: none;
                    }
                  }
                }
              }

              &__heading {
                margin-top: 10px;

                h1 {
                  color: $carousel-pink;
                  margin: 0px;

                  br {
                    @media #{$small-device} {
                      display: none;
                    }
                  }
                }
              }
            }

            &__sub-title {
              margin-top: 25px;

              h3 {
                color: $tussock;
                margin: 0px;
                font-weight: 400;
                font-size: 1.5rem;
              }
            }

            &__description {
              display: flex;
              flex-direction: column;
              margin-top: 35px;

              p {
                color: $carousel-pink;

                &:first-child {
                  margin-top: 0px;
                  margin-bottom: 0px;
                }
              }
            }
          }
        }
      }

      .tns-visually-hidden {
        display: none;
      }

      .tns-outer {
        width: 100%;
      }
    }

    &__navigation {
      position: absolute;
      z-index: 1;
      bottom: 0px;
      left: 0px;
      right: 0px;
      width: 92%;
      margin: 0px auto;
      background-color: transparent;
      display: flex;
      justify-content: center;

      @media #{$large-device} {
        width: 25%;
        margin-left: 10px;
        height: 100%;
        top: 0px;
        bottom: auto;
        left: auto;
        right: 0px;
        background-color: rgba($color: $mako, $alpha: 0.25);
      }

      &--container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        width: calc(100% - 90px);

        @media #{$large-device} {
          width: 100%;
          flex-direction: column;
          height: 85%;
          margin: auto 0px;
          position: relative;
          overflow: visible;
        }

        &::before {
          content: "";
          position: absolute;
          top: 5px;
          left: 0px;
          right: 0px;
          height: 1px;
          width: 100%;
          background-color: $carousel-pink;

          @media #{$large-device} {
            left: 0px;
            right: auto;
            top: 0px;
            height: 100%;
            width: 1px;
          }
        }
      }

      &__slides {
        padding: 0px 0px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        @media #{$large-device} {
          flex-direction: column;
          align-items: flex-start;
        }

        &__slide {
          position: relative;
          cursor: pointer;

          &:hover {
            .hero__timeline__navigation__slides__slide__title {
              h3 {
                color: rgba($color: $tussock, $alpha: 1);
              }
            }
          }

          @media #{$large-device} {
            margin-left: 45px;
          }

          &.current-slide {
            h3 {
              color: $tussock;
            }

            .hero__timeline__navigation__slides__slide__title {
              &::before {
                content: "";
                margin: 0px auto;
                position: absolute;
                top: 1px;
                left: 0px;
                right: 0px;
                height: 10px;
                width: 10px;
                border-radius: 50%;
                background-color: $tussock;

                @media #{$large-device} {
                  left: -49px;
                  right: auto;
                  top: calc(50% - 5px);
                  bottom: auto;
                  margin: auto 0px;
                }
              }
            }
          }

          &.tns-slide-active {
            &.current-slide {
              .hero__timeline__navigation__slides__slide__title {
                &::before {
                  content: "";
                  margin: 0px auto;
                  position: absolute;
                  top: 1px;
                  left: 0px;
                  right: 0px;
                  height: 10px;
                  width: 10px;
                  border-radius: 50%;
                  background-color: $tussock;

                  @media #{$large-device} {
                    left: -49px;
                    right: auto;
                    top: calc(50% - 5px);
                    bottom: auto;
                    margin: auto 0px;
                  }
                }
              }
            }
          }

          &__title {
            padding: 30px 7.5px 25px 7.5px;

            @media #{$large-device} {
              padding-left: 0px;
              padding-right: 0px;
            }

            h3 {
              transition: all 0.25s linear;
              text-align: center;
              color: $carousel-pink;
              width: 100%;
              margin: 0px;
              padding: 0px;
              font-size: 1.25rem;
            }
          }
        }
      }

      &__previous,
      &__next {
        position: absolute;
        cursor: pointer;

        svg {
          color: $carousel-pink;
          width: 35px;
          height: 35px;
          transition: all 0.25s linear;

          &:hover,
          &:focus {
            color: rgba($color: $tussock, $alpha: 0.75);
          }
        }
      }

      &__previous {
        left: 0px;

        @media #{$large-device} {
          left: 40px;
          top: 0px;
        }

        svg {
          @media #{$large-device} {
            transform: rotate(90deg);
          }
        }
      }

      &__next {
        right: 0px;

        @media #{$large-device} {
          right: auto;
          left: 40px;
          bottom: 0px;
        }

        svg {
          @media #{$large-device} {
            transform: rotate(90deg);
          }
        }
      }

      .tns-visually-hidden {
        display: none;
      }

      .tns-outer {
        width: 100%;
      }
    }
  }
}
