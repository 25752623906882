/******************************************************************************************/
/* Container CTA
/* This file is use to store the container.
/******************************************************************************************/

.container { // <576px;
  max-width: $extra-small-m-w; // $extra-small-m-w: 92%;
  width: 100%;
  margin: 0 auto;

  @media #{$small-device} { // >576px;
    max-width: $small-m-w; // $small-m-w: 540px;
  }

  @media #{$medium-device} { // >768px;
    max-width: $medium-m-w; // $medium-m-w: 720px;
  }

  @media #{$large-device} { // >992px;
    max-width: $large-m-w; // $large-m-w: 960px;
  }

  @media #{$extra-large-device} { // >1200px;
    max-width: $extra-large-m-w; // $extra-large-m-w: 1140px;
  }

  @media #{$extra-extra-large-device} { // >1400px;
    max-width: $extra-extra-large-m-w; // $extra-extra-large-m-w: 1320px;
  }

  &--fluid {
    max-width: 100%;
    width: 100%;
  }
}
