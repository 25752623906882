/******************************************************************************************/
/* Checkerboard
/* This file is use to store the checkerboard.
/******************************************************************************************/

.checkerboard {
  width: 100%;
  height: 100%;
  background-color: rgba($color: $white, $alpha: 0.75);

  .title-block {
    background-color: transparent;
    padding-bottom: 0px;
  }

  .title-block--container {
    padding-bottom: 0px;
  }

  &--container {
    width: 100%;
    padding: 30px 0px;

    @media #{$medium-device} {
      padding: 60px 0px;
      background-color: rgba($color: $laurel, $alpha: 1);
    }
  }

  &__layout {
    background-color: rgba($color: $white, $alpha: 0.75);
    display: flex;
    height: 100%;

    @media #{$medium-device} {
      background-color: transparent;
    }

    &--left {
      flex-direction: column;

      @media #{$medium-device} {
        flex-direction: row;
      }

      .checkerboard__layout__column--content {
        background-color: rgba($color: $laurel, $alpha: 1);

        @media #{$medium-device} {
          background-color: rgba($color: $white, $alpha: 1);
        }
      }

      .checkerboard {
        &__layout {
          &__content {
            &__sub-header,
            &__header,
            &__description,
            &__cta-buttons {
              @media #{$medium-device} {
                text-align: left;
                justify-content: start;
              }
            }
          }
        }
      }
    }

    &--right {
      flex-direction: column-reverse;

      @media #{$medium-device} {
        flex-direction: row;
      }

      .checkerboard__layout__column--content {
        background-color: rgba($color: $laurel, $alpha: 1);

        @media #{$medium-device} {
          background-color: rgba($color: $white, $alpha: 1);
        }
      }

      .checkerboard {
        &__layout {
          &__content {
            &__sub-header,
            &__header,
            &__description,
            &__cta-buttons {
              @media #{$medium-device} {
                text-align: right;
                justify-content: end;
              }
            }
          }
        }
      }
    }

    &__column {
      display: flex;
      flex: 1;

      &--image {
        height: 100%;
        width: 100%;

        @media #{$medium-device} {
          min-height: 350px;
          max-height: 450px;
          height: auto;
        }

        img {
          object-fit: contain;
          object-position: bottom;

          @media #{$medium-device} {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      padding: 30px 0;

      @media #{$medium-device} {
        padding: 30px 0px;
      }

      &__sub-header,
      &__header,
      &__description,
      &__cta-buttons {
        display: flex;
        text-align: center;
        justify-content: center;

        @media #{$small-device} {
          width: 90%;
        }

        @media #{$medium-device} {
          width: 85%;
        }
      }

      &__sub-header {
        span {
          color: $white;
          font-size: 1.25rem;

          @media #{$medium-device} {
            color: $mako;
          }
        }
      }

      &__header {
        margin-top: 10px;

        h2,
        h3 {
          margin: 0px;
          color: $white;

          @media #{$medium-device} {
            color: $mako;
          }

          br {
            @media #{$small-device} {
              display: none;
            }
          }
        }
      }

      &__description {
        margin-top: 35px;

        p {
          color: $white;

          @media #{$medium-device} {
            color: $mako;
          }

          &:first-child {
            margin-top: 0px;
          }
        }
      }

      &__cta-buttons {
        margin-top: 25px;
        height: auto;
      }
    }
  }

  &--bg-dingley {
    .checkerboard--container {
      @media #{$medium-device} {
        background-color: transparent;
      }
    }

    .checkerboard__layout--left {
      .checkerboard__layout__column--content {
        background-color: rgba($color: $dingley, $alpha: 1);

        @media #{$medium-device} {
          //background-color: rgba($color: $white, $alpha: 1);
        }
      }
    }

    .checkerboard__layout--right {
      .checkerboard__layout__column--content {
        background-color: rgba($color: $dingley, $alpha: 1);

        @media #{$medium-device} {
          //background-color: rgba($color: $white, $alpha: 1);
        }
      }
    }

    .checkerboard__layout__content {
      &__sub-header,
      &__header,
      &__description,
      &__cta-buttons {
        span {
          color: $tussock;
        }

        h2,
        h3 {
          color: $white;
          font-weight: 500;
        }

        p {
          color: $white;
        }
      }
    }
  }

  &--bg-sites {
    .checkerboard--container {
      @media #{$medium-device} {
        background-color: transparent;
      }
    }

    .checkerboard__layout__column--content {
      &--bg-stickney-meadows {
        background-color: rgba($color: $stickney-meadows, $alpha: 1);
      }

      &--bg-stickney-chase {
        background-color: rgba($color: $stickney-chase, $alpha: 1);
      }
    }

    .checkerboard__layout__content {
      &__sub-header,
      &__header,
      &__description,
      &__cta-buttons {
        span {
          color: $tussock;
        }

        h2,
        h3 {
          color: $white;
          font-weight: 500;
        }

        p {
          color: $white;
        }
      }
    }
  }
}
