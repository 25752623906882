/******************************************************************************************/
/* Full Width CTA
/* This file is use to store the full width cta.
/******************************************************************************************/

.full-width-cta {
  background-color: rgba($color: $laurel, $alpha: 1);
  width: 100%;
  height: 100%;

  &--container {
    padding: 30px 0px;

    @media #{$medium-device} {
      padding: 60px 0px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    &__sub-header,
    &__header,
    &__description,
    &__cta-buttons {
      display: flex;
      text-align: center;
      justify-content: center;

      @media #{$medium-device} {
        width: 75%;
      }

      @media #{$large-device} {
        width: 60%;
      }

      @media #{$extra-large-device} {
        width: 50%;
      }

      @media #{$extra-extra-large-device} {
        width: 45%;
      }
    }

    &__sub-header {
      span {
        color: $white;
        font-size: 1.25rem;
      }
    }

    &__header {
      margin-top: 10px;

      h2 {
        margin: 0px;
        color: $white;

        br {
          @media #{$small-device} {
            display: none;
          }
        }
      }
    }

    &__description {
      margin-top: 35px;

      p {
        color: $white;

        &:first-child {
          margin-top: 0px;
        }
      }
    }

    &__cta-buttons {
      margin-top: 25px;
      height: auto;
    }
  }

  &--bg-dingley {
    background-color: rgba($color: $dingley, $alpha: 1);

    .full-width-cta__content__sub-header {
      span {
        color: $tussock;
      }
    }
  }
}
