/******************************************************************************************/
/* Contained CTA
/* This file is use to store the contained cta.
/******************************************************************************************/

.contained-cta {
  background-color: rgba($color: $white, $alpha: 0.75);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &--container {
    background-color: rgba($color: $laurel, $alpha: 1);
    max-width: $extra-small-m-w;
    width: 100%;

    @media #{$small-device} {
      min-width: $small;
    }

    @media #{$medium-device} {
      min-width: $medium;
    }

    @media #{$large-device} {
      min-width: $large;
    }

    @media #{$extra-large-device} {
      min-width: $extra-large;
    }

    @media #{$extra-extra-large-device} {
      min-width: $extra-extra-large;
    }
  }

  &--bg-dingley {
    .contained-cta--container {
      background-color: rgba($color: $dingley, $alpha: 1);
    }
  }

  &--bg-tussock {
    .contained-cta--container {
      background-color: rgba($color: $tussock, $alpha: 1);
    }
  }

  &--bg-stickney-meadows {
    .contained-cta--container {
      background-color: rgba($color: $stickney-meadows, $alpha: 1);
    }
  }

  &--bg-stickney-chase {
    .contained-cta--container {
      background-color: rgba($color: $stickney-chase, $alpha: 1);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 30px 0px;

    @media #{$medium-device} {
      padding: 60px 0px;
    }

    &__sub-header,
    &__header,
    &__description,
    &__cta-buttons {
      display: flex;
      text-align: center;
      justify-content: center;

      @media #{$medium-device} {
        width: 75%;
      }

      @media #{$large-device} {
        width: 60%;
      }

      @media #{$extra-large-device} {
        width: 50%;
      }

      @media #{$extra-extra-large-device} {
        width: 45%;
      }
    }

    &__sub-header {
      span {
        color: $white;
        font-size: 1.25rem;
      }
    }

    &__header {
      margin-top: 10px;

      h2 {
        margin: 0px;
        color: $white;

        br {
          @media #{$small-device} {
            display: none;
          }
        }
      }
    }

    &__description {
      margin-top: 35px;

      p {
        color: $white;

        &:first-child {
          margin-top: 0px;
        }
      }
    }

    &__cta-buttons {
      margin-top: 25px;
      height: auto;
    }
  }
}
